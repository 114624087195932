<script>
  import Badge from "@smui-extra/badge";

  export let badgeNum;
</script>

<Badge
  style="position: absolute; top: 6px; right: 50%; transform: translateX(40px);"
  >{badgeNum}</Badge
>
