<script>
  import { getContext, onMount } from "svelte";
  import { fade } from "svelte/transition";

  import HelpBase from "~/components/help/HelpBase.svelte";
  import HelpBeforeLogin from "~/components/help/HelpBeforeLogin.svelte";
  import backendApi from "~/libs/backendApi";
  import { CONTEXT_KEY_APP, CONTEXT_KEY_USER } from "~/libs/constants";
  import reminder from "~/libs/reminder";
  import InitialPasswordChange from "~/pages/Login/InitialPasswordChange.svelte";
  import NormalLogin from "~/pages/Login/NormalLogin.svelte";
  import PasswordReset from "~/pages/Login/PasswordReset.svelte";

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** ユーザーID */
  let id = "";
  /** パスワード*/
  let pw = "";

  /** 初期パスワード変更ページを表示するか否か */
  let showsInitialPasswordChangePage = false;

  /** パスワードリセットページを表示するか否か */
  let showsPasswordResetPage = false;

  /** ログイン画面の初回表示ヘルプを表示するか否か */
  let showsFirstLoginHelp = false;

  onMount(async () => {
    if (userContext.iosNativeAppPushInfo) {
      // ネイティブアプリの場合はデバイストークンを削除
      await execDeleteApnsDeviceToken(
        userContext.iosNativeAppPushInfo.bundleId,
        userContext.iosNativeAppPushInfo.deviceToken,
      );
    }
    userContext.erase();
    reminder.remindOff();
    showFirstLoginHelpIfNeeded();
  });

  /**
   * デバイストークン削除
   * @param {string} bundleId
   * @param {string} deviceToken
   */
  async function execDeleteApnsDeviceToken(bundleId, deviceToken) {
    backendApi
      .deleteApnsDeviceToken({ bundleId: bundleId, deviceToken: deviceToken })
      .then(() => {
        console.log("デバイストークン削除成功");
      })
      .catch((e) => {
        console.error("デバイストークン削除失敗", e);
      });
  }

  /**
   * 初めてのログインの際にヘルプページを表示する
   */
  function showFirstLoginHelpIfNeeded() {
    if (!appContext.firstLoginOpened) {
      // @ts-ignore: 入力フォームのフォーカスを外して仮想キーボードやパスワードの自動入力提案が表示されるのを避ける
      document?.activeElement?.blur();

      appContext.firstLoginOpened = showsFirstLoginHelp = true;
      appContext.store();
    }
  }
</script>

<main in:fade>
  <div class="wrapper">
    {#if showsInitialPasswordChangePage}
      <!-- 初期パスワード変更 -->
      <InitialPasswordChange {id} bind:pw bind:showsInitialPasswordChangePage />
    {:else if showsPasswordResetPage}
      <!-- パスワードリセット -->
      <PasswordReset bind:id bind:pw bind:showsPasswordResetPage />
    {:else}
      <!-- 通常ログイン -->
      <NormalLogin
        bind:id
        bind:pw
        bind:showsInitialPasswordChangePage
        bind:showsPasswordResetPage
      />
    {/if}
  </div>
</main>

<!-- ヘルプ表示 -->
{#if showsFirstLoginHelp}
  <HelpBase
    helpContents={HelpBeforeLogin}
    clickConfirm={() => {
      showsFirstLoginHelp = false;
    }}
  />
{/if}

<style lang="scss">
  main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    line-height: 1.4;
    overflow: auto;

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: url(../assets/images/login_background.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      opacity: 0.85;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 280px;
    margin: auto;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.863);
    border-radius: 20px;

    :global(.heading .title) {
      font-size: larger;
      font-weight: bold;
    }

    :global(.pageOverview) {
      margin-top: 20px;
      line-height: 20px;
    }

    :global(.inputField) {
      :global(.mdc-text-field) {
        width: 280px;
        background-color: #fff;
      }
    }

    :global(.passwordField) {
      :global(.mdc-icon-button) {
        margin: auto 0;
      }
    }

    :global(.errorMessage) {
      margin-top: 8px;
      color: rgb(228, 4, 4);
      font-size: 14px;
      font-weight: 600;
    }

    :global(.passwordStrengthChecker) {
      margin-top: 5px;
    }

    :global(.command) {
      margin-top: 30px;
    }
  }
</style>
