<script>
  import Button, { Label } from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import Radio from "@smui/radio";
  import Select, { Option } from "@smui/select";
  import { getContext } from "svelte";

  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import { ConfirmDialogTypes } from "~/libs/constants";
  import {
    ACCEPT_DENIED,
    CONTEXT_KEY_USER,
    PAYMENT_NOT_POSSIBLE,
    PaymentNotPossibleDetectionTypes,
    TROUBLE_ADDRESS_UNKNOWN,
    TROUBLE_LOST_OR_DAMAGED,
    TROUBLE_NON_ADDRESS,
    UNABLE_TO_DELIVER_TODAY,
    acceptDeniedReasonTypes,
  } from "~/libs/constants";
  import deliveryListUtils from "~/libs/deliveryListUtils";
  import { deliveryTarget } from "~/libs/stores";

  /** 配達不可の場合の理由区分 @type {number} */
  export let extraEventType;

  /** @type {import("~/libs/constants").PaymentNotPossibleDetectionTypes} 代引き金額支払不可の検知タイプ*/
  export let paymentNotPossibleType = undefined;

  /** @type {import("~/libs/constants").acceptDeniedReasonTypes} 受取拒否の理由区分 */
  export let acceptDeniedReasonType = undefined;
  $: if (acceptDeniedReasonType !== undefined) {
    extraEventTypeValue = String(ACCEPT_DENIED);
  }

  /** @type {number} 紛失した荷物の個数 */
  export let numberOfLost = undefined;

  /** @type {boolean} 紛失登録を行なう場合にtrue */
  export let lostRegistrationFlag;

  /** @type {boolean} 紛失調査中として登録する場合にtrue */
  export let searchingForPackageFlag;

  /** @type {import("~/libs/commonTypes").DeliveryPackage} 配達対象の荷物 */
  let deliveryPackage = $deliveryTarget;

  /**
   * 確認ダイアログコンポーネント（住所不明の理由選択用）のインスタンス
   * @type {ConfirmDialog}
   */
  let dialog;

  /** @type {"代引支払不可" | "本日配達不可" | "住所不明"  | "受取拒否" | "損壊" | "紛失"} */
  let dialogType;

  /** 配達不可の理由 @type {string} */
  let extraEventTypeValue;

  /**
   * @type {boolean} 車の中を探したが、見つからなかった場合
   */
  let searchedCarNotFound = false;
  /**
   * @type {boolean} 配達リストに登録された荷物と手元に残っている荷物の個数を確認したが、見つからなかった場合
   */
  let checkedDeliveryListNotFound = false;
  /**
   * @type {boolean} 落とした心当たりのある場所に戻って確認したが、見つからなかった場合
   */
  let searchedLostPlaceNotFound = false;
  /**
   * @type {boolean} 配送センターを確認したが、見つからなかった場合
   */
  let checkedDeliveryCenterNotFound = false;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {Array<import("~/libs/commonTypes").DeliveryPackage>} */
  const shippingList = userContext.deliveryList ?? [];

  /** @type {import("~/libs/commonTypes").DeliveryPackage} 更新対象の荷物 */
  const updateTarget = deliveryListUtils.findByTrackingNumber(
    shippingList,
    deliveryPackage.trackingNumber,
  );

  /** @type {number} 未配達の荷物の個数 */
  let numberOfUndeliveredPackages = 0;

  /** @type {Array<number>} 複数口の荷物の個数のリスト */
  let multiplePackagesNumList = [];

  /**
   * @type {number} 推定される、車内に残っている荷物の数
   * 複数口の場合は、未配達の荷物から紛失した数（テキストボックスに入力した値）を引いた数、
   * 複数口ではない場合は、未配達の荷物から1を引いた数が車内に残っていると考えられる
   */
  $: estimatedCarRemainingItems =
    deliveryPackage.numberOfPackages > 1
      ? numberOfUndeliveredPackages - numberOfLost
      : numberOfUndeliveredPackages - 1;

  // numberOfLostの変更に伴い実行するリアクティブ処理
  $: {
    onChangeNumberOfLost(
      // @ts-ignore
      numberOfLost,
    );
  }

  /**
   * 紛失した荷物の工数が変更された場合に呼び出されるリアクティブハンドラー
   * 車内に残っている荷物の個数確認チェックを未チェックに戻す
   */
  function onChangeNumberOfLost() {
    checkedDeliveryListNotFound = false;
  }

  /**
   * ダイアログを開く
   * @param {"代引支払不可" | "本日配達不可" | "住所不明"  | "受取拒否" | "損壊" | "紛失"} type
   */
  export function openDialog(type) {
    dialogType = type;
    if (type === "代引支払不可") {
      paymentNotPossibleType = undefined;
      extraEventTypeValue = "";
    } else if (type === "本日配達不可") {
      extraEventTypeValue = String(UNABLE_TO_DELIVER_TODAY);
    } else if (type === "住所不明") {
      extraEventTypeValue = "";
    } else if (type === "受取拒否") {
      extraEventTypeValue = "";
    } else if (type === "損壊") {
      extraEventTypeValue = String(TROUBLE_LOST_OR_DAMAGED);
    } else if (type === "紛失") {
      extraEventTypeValue = String(TROUBLE_LOST_OR_DAMAGED);

      // 未配達(配達不可を含む)の荷物の個数を取得
      numberOfUndeliveredPackages = 0;
      for (let i = 0; i < shippingList.length; i++) {
        if (shippingList[i].statusText !== "済") {
          numberOfUndeliveredPackages += shippingList[i].numberOfPackages ?? 1;
        }
      }
      console.log(numberOfUndeliveredPackages);

      if (deliveryPackage.numberOfPackages > 1) {
        // 複数口の場合、セレクトボックス用の個口数リストを作成
        for (let i = 0; i < deliveryPackage.numberOfPackages; i++) {
          multiplePackagesNumList.push(i + 1);
        }
      }
    }
    dialog.openDialog();
  }
</script>

<ConfirmDialog
  bind:this={dialog}
  type={ConfirmDialogTypes.CLOSE}
  mandatory={true}
  onDialogClosedHandler={async (event) => {
    if (
      event.detail.action === "close" &&
      searchingForPackageFlag &&
      !updateTarget.searchingForPackage
    ) {
      extraEventType = Number.parseInt(extraEventTypeValue);
    }
  }}
>
  <svelte:fragment slot="title">
    {#if dialogType === "住所不明"}
      住所不明の詳細を選択
    {:else}
      登録確認
    {/if}
  </svelte:fragment>
  <svelte:fragment slot="content">
    {#if dialogType === "住所不明"}
      <div class="notice">
        単純な誤字脱字程度の誤りの場合は、配達リストの地図アイコンをタップして、地図下部の「住所を手入力」から補正してください。
      </div>

      <div class="typeArea">
        <FormField>
          <Radio
            bind:group={extraEventTypeValue}
            value={String(TROUBLE_NON_ADDRESS)}
          />
          <span slot="label" style="font-size: 16px; font-weight: 600;"
            >住所の指定に不備がある</span
          >
        </FormField>
        <div class="examples">
          <p>指定住所に住居が存在しない</p>
          <p>集合住宅だが部屋番号の指定がない 等</p>
        </div>
      </div>

      <div class="typeArea">
        <FormField>
          <Radio
            bind:group={extraEventTypeValue}
            value={String(TROUBLE_ADDRESS_UNKNOWN)}
          />
          <span slot="label" style="font-size: 16px; font-weight: 600;"
            >住居位置が特定できない</span
          >
        </FormField>
        <div class="examples">
          <p>地図アプリで住居位置が特定できない</p>
          <p>地図アプリが示す場所が誤っている 等</p>
        </div>
      </div>
    {:else if dialogType === "受取拒否"}
      <p class="description">受け取りを拒否された理由を選択ください。</p>

      <div class="typeArea">
        <FormField>
          <Radio
            bind:group={acceptDeniedReasonType}
            value={acceptDeniedReasonTypes.WRONG_DESTINATION_NAME}
          />
          <span slot="label" style="font-size: 16px; font-weight: 600;"
            >宛名が誤っている</span
          >
        </FormField>
        <FormField>
          <Radio
            bind:group={acceptDeniedReasonType}
            value={acceptDeniedReasonTypes.NO_LONGER_NEEDED}
          />
          <span slot="label" style="font-size: 16px; font-weight: 600;"
            >注文したが必要無くなった</span
          >
        </FormField>
        <FormField>
          <Radio
            bind:group={acceptDeniedReasonType}
            value={acceptDeniedReasonTypes.OTHERS}
          />
          <span slot="label" style="font-size: 16px; font-weight: 600;"
            >心当たりがない・その他理由不明</span
          >
        </FormField>
      </div>
    {:else if dialogType === "代引支払不可"}
      <p class="description">
        代引き料金が用意できていないことを確認したタイミングを選択ください。
      </p>

      <div class="typeArea">
        <FormField>
          <Radio
            bind:group={paymentNotPossibleType}
            value={PaymentNotPossibleDetectionTypes.BEFORE_ARRIVAL}
            on:change={() => {
              extraEventTypeValue = String(PAYMENT_NOT_POSSIBLE);
            }}
          />
          <span slot="label" style="font-size: 16px; font-weight: 600;"
            >配達先に移動する前(電話で確認)</span
          >
        </FormField>
        <FormField style="margin-top: -3px;">
          <Radio
            bind:group={paymentNotPossibleType}
            value={PaymentNotPossibleDetectionTypes.AFTER_ARRIVAL}
            on:change={() => {
              extraEventTypeValue = String(PAYMENT_NOT_POSSIBLE);
            }}
          />
          <span slot="label" style="font-size: 16px; font-weight: 600;"
            >荷物を対面で渡す時</span
          >
        </FormField>
      </div>
    {:else if dialogType === "本日配達不可"}
      <p class="description">「本日配達不可」として登録します。</p>

      <div class="examples">
        【典型的な例】
        <p>持ち出した荷物を当日中に配達しきれなかった</p>
      </div>
    {:else if dialogType === "損壊"}
      <p class="description">荷物の状態を確認してください。</p>
      <ul class="categorizationList">
        <li>
          <p class="damagedPattern">外装のみ損壊している</p>
          <p>
            損壊登録は<span class="emphasis">不要</span>です。<br />
            通常通り配達を行ってください。
          </p>
        </li>
        <li>
          <p class="damagedPattern">中身まで損壊している(可能性がある)</p>
          <p>
            EC事業者への返品の為、損壊登録が<span class="emphasis">必要</span
            >です。<br />
            「登録する」ボタンを押し、荷物を持ち帰ったうえで返品登録を行ってください。
          </p>
        </li>
      </ul>
      <div class="buttonArea">
        <Button
          class="registerButton"
          color="secondary"
          variant="unelevated"
          disabled={extraEventTypeValue === ""}
          on:click={async () => {
            extraEventType = Number.parseInt(extraEventTypeValue);
          }}
        >
          <Label>登録する</Label>
        </Button>
      </div>
    {:else if dialogType === "紛失"}
      {#if !searchingForPackageFlag}
        <p class="description">
          以下の手順に沿って確認を行い、完了したらチェックを入れてください。
        </p>

        {#if deliveryPackage.numberOfPackages > 1}
          <div class="inputFieldOfLost">
            <span>紛失した個数</span>
            <Select variant="outlined" bind:value={numberOfLost}>
              <Option hidden value=""></Option>
              {#each multiplePackagesNumList as num}
                <Option value={num}>{num}</Option>
              {/each}
            </Select>
            <span>個</span>
          </div>
        {/if}
        <div class="lostCheck">
          <FormField>
            <Checkbox
              bind:checked={searchedCarNotFound}
              disabled={deliveryPackage.numberOfPackages > 1 &&
                !Number.isInteger(numberOfLost)}
            />
            <span slot="label">車の中を探したが、見つからなかった</span>
          </FormField>
          <FormField>
            <Checkbox
              bind:checked={checkedDeliveryListNotFound}
              disabled={deliveryPackage.numberOfPackages > 1 &&
                !Number.isInteger(numberOfLost)}
            />
            <span slot="label"
              >車内に残っている荷物の数が<b>{estimatedCarRemainingItems}個</b
              >になっている</span
            >
          </FormField>
        </div>
        <div class="additionalDescription">
          <p>
            数が合わない場合は誤配している可能性が高いので、配達完了にした荷物が手元に残っていないか確認してください。
          </p>
        </div>

        <div class="buttonArea">
          <Button
            class="keepButton"
            color="primary"
            variant="unelevated"
            disabled={!searchedCarNotFound || !checkedDeliveryListNotFound}
            on:click={() => {
              searchingForPackageFlag = true;
            }}
          >
            <Label>次へ</Label>
          </Button>
        </div>
      {:else}
        <p class="description">
          以下の手順に沿って確認を行い、完了したらチェックを入れてください。
        </p>

        <FormField>
          <Checkbox bind:checked={searchedLostPlaceNotFound} />
          <span slot="label"
            >落とした心当たりのある場所に戻って確認したが、見つからなかった</span
          >
        </FormField>
        <FormField>
          <Checkbox bind:checked={checkedDeliveryCenterNotFound} />
          <span slot="label">配送センターを確認したが、見つからなかった</span>
        </FormField>
        <p class="attention">
          「紛失」として登録した荷物は配達リストから削除されます。<br
          />リスト上での確認ができなくなるため、この場で必ず<span
            >配達管理者へ報告</span
          >してください。
        </p>
        <div class="buttonArea">
          <Button
            class="registerButton"
            color="secondary"
            variant="unelevated"
            disabled={!searchedLostPlaceNotFound ||
              !checkedDeliveryCenterNotFound}
            on:click={async () => {
              lostRegistrationFlag = true;
              extraEventType = Number.parseInt(extraEventTypeValue);
            }}
          >
            <Label>登録する</Label>
          </Button>
        </div>
      {/if}
    {/if}

    {#if dialogType != "損壊" && dialogType != "紛失"}
      <div class="buttonArea">
        <Button
          class="registerButton"
          color="secondary"
          variant="unelevated"
          disabled={extraEventTypeValue === ""}
          on:click={() => {
            extraEventType = Number.parseInt(extraEventTypeValue);
          }}
        >
          <Label>登録する</Label>
        </Button>
      </div>
    {/if}
  </svelte:fragment>
</ConfirmDialog>

<style lang="scss">
  .description {
    margin-bottom: 6px;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
  }

  .notice {
    padding: 4px 6px;
    background-color: #ffe8e8;
    border-radius: 4px;
    font-size: 13px;
    line-height: normal;
    letter-spacing: normal;
  }

  .typeArea {
    .examples {
      margin-left: 16px;
    }
  }

  .examples {
    margin-left: 8px;

    > p {
      display: flex;
      line-height: normal;
      letter-spacing: normal;

      &::before {
        content: "・";
      }
    }
  }

  .categorizationList {
    padding-left: 16px;
    font-size: 14px;
    line-height: normal;

    .damagedPattern {
      font-size: 15px;
      font-weight: bold;
      margin: 4px 0;
    }

    li {
      list-style-type: square;
    }

    li::marker {
      color: #018786;
    }

    li:first-of-type {
      margin-bottom: 8px;
    }

    .emphasis {
      color: #f00;
    }
  }

  .attention {
    background-color: #fae6e6;
    color: #333;
    font-size: 14px;
    padding: 8px;
    border-radius: 2px;

    span {
      color: #f00;
    }
  }

  .inputFieldOfLost {
    color: #333;
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding-left: 10px;

    :global(.mdc-select) {
      margin: 0 10px;
      width: 100px;
    }
    :global(
        .mdc-select .mdc-select__menu .mdc-deprecated-list-item[hidden="true"]
      ) {
      display: none;
    }
  }

  .lostCheck {
    :global(
        .mdc-checkbox
          .mdc-checkbox__native-control[disabled]:not(:checked):not(
            :indeterminate
          ):not([data-indeterminate="true"])
          ~ .mdc-checkbox__background
      ) {
      background-color: rgba(0, 0, 0, 0.1);
      border-color: rgba(0, 0, 0, 0.2);
    }
  }

  .additionalDescription {
    margin-left: 46px;
    padding-left: 10px;
    border-left: 3px solid #018786;
    font-size: 13px;
    line-height: normal;
  }

  .buttonArea {
    margin-top: 15px;

    :global(.keepButton),
    :global(.registerButton) {
      width: 100%;
    }
  }
</style>
