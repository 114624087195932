<svg
  width="432.1"
  height="543.21"
  version="1.1"
  viewBox="0 0 432.1 543.21"
  xmlns="http://www.w3.org/2000/svg"
  class="svg-icons-back-camera"
>
  <defs>
    <clipPath id="clipPath198">
      <path d="m4e3 2222.2h-4e3v-2222.2h4e3z" />
    </clipPath>
    <clipPath id="clipPath204">
      <path d="m.060059 2222.3h4e3v-2222.3h-4e3z" />
    </clipPath>
  </defs>
  <g transform="matrix(1.3333 0 0 -1.3333 -722.36 2410.5)">
    <g clip-path="url(#clipPath198)">
      <g clip-path="url(#clipPath204)">
        <g transform="translate(1632.7,534.22)">
          <g transform="translate(-120.37,277.78)">
            <g transform="translate(63.23,81.74)">
              <g transform="translate(-137.3,-137.3)">
                <g transform="translate(-26.19,137.3)">
                  <g transform="translate(128.04,-128.04)">
                    <g transform="translate(-46.3,-9.26)">
                      <g transform="translate(92.6,55.56)">
                        <g transform="translate(-166.67)">
                          <g transform="translate(1125.5 -263.89)">
                            <g transform="translate(-129.63,9.26)">
                              <g transform="translate(18.52,231.48)">
                                <g transform="translate(18.52,-157.41)">
                                  <g transform="translate(92.59,-9.26)">
                                    <g transform="translate(0 880.14)">
                                      <g transform="translate(0,-64.815)">
                                        <g transform="translate(-129.63,9.259)">
                                          <g
                                            transform="translate(1034.4 55.556)"
                                          >
                                            <g
                                              transform="translate(-296.29,-37.038)"
                                            >
                                              <g
                                                transform="translate(111.11 194.44)"
                                              >
                                                <g
                                                  transform="translate(231.48 -843.1)"
                                                >
                                                  <g
                                                    transform="translate(-9.26,-129.63)"
                                                  >
                                                    <g
                                                      transform="translate(-194.44,64.81)"
                                                    >
                                                      <g
                                                        transform="translate(27.77,-111.11)"
                                                      >
                                                        <g
                                                          transform="translate(111.12,-27.78)"
                                                        >
                                                          <g
                                                            transform="translate(-2676.7 870.88)"
                                                          >
                                                            <path
                                                              d="m0 0v-46.296c0-15.278-12.5-27.778-27.778-27.778h-148.15c-15.279 0-27.779 12.5-27.779 27.778v333.33c0 15.278 12.5 27.778 27.779 27.778h148.15c15.278 0 27.778-12.5 27.778-27.778v-64.814"
                                                              fill="none"
                                                              stroke="#000"
                                                              stroke-miterlimit="10"
                                                              stroke-width="18.518"
                                                            />
                                                            <g
                                                              transform="translate(-148.15 268.52)"
                                                            >
                                                              <path
                                                                d="m0 0c0-5.114-4.146-9.259-9.259-9.259-5.118 0-9.26 4.145-9.26 9.259 0 5.113 4.142 9.259 9.26 9.259 5.113 0 9.259-4.146 9.259-9.259z"
                                                                fill="none"
                                                                stroke="#000"
                                                                stroke-miterlimit="10"
                                                                stroke-width="18.518"
                                                              />
                                                              <g
                                                                transform="translate(185.18 -101.85)"
                                                              >
                                                                <path
                                                                  d="m0 0h64.815v-122.81c0-8.882-7.198-16.081-16.081-16.081h-171.54c-8.884 0-16.082 7.199-16.082 16.081v122.81h64.814l9.259 27.777h55.556z"
                                                                  fill="none"
                                                                  stroke="#000"
                                                                  stroke-miterlimit="10"
                                                                  stroke-width="18.518"
                                                                />
                                                                <g
                                                                  transform="translate(0,-64.814)"
                                                                >
                                                                  <path
                                                                    d="m0 0c0-20.456-16.588-37.038-37.037-37.038-20.447 0-37.037 16.582-37.037 37.038 0 20.455 16.59 37.036 37.037 37.036 20.449 0 37.037-16.581 37.037-37.036z"
                                                                    fill="none"
                                                                    stroke="#000"
                                                                    stroke-miterlimit="10"
                                                                    stroke-width="18.518"
                                                                  />
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
