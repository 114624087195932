<script>
  import Button, { Label } from "@smui/button";
  import Dialog, { Content, Header, Title } from "@smui/dialog";
  import IconButton from "@smui/icon-button";
  import Select, { Option } from "@smui/select";
  import Textfield from "@smui/textfield";
  import { getContext } from "svelte";
  import { createEventDispatcher } from "svelte";

  import { CHOICES_OF_TIME, CONTEXT_KEY_APP } from "~/libs/constants";

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  const dispatch = createEventDispatcher();

  /** @type {boolean} ダイアログの開閉フラグ */
  let dialogOpened = false;

  /** @type {boolean} ESCキーやblurでダイアログを閉じないようにするか否か */
  export let mandatory = true;

  /**
   * @callback onDialogClosedCallback
   * @param {CustomEvent<{ action: "ok" | "cancel" | "close" }>} event
   */
  /**
   * @type {onDialogClosedCallback}
   */
  let onDialogClosedHandler = () => {};

  /** @type {Array<import("~/libs/commonTypes").TimeFramePreset>} 時間帯のプリセットリスト */
  let presetList = appContext.timeFramePresetList;

  /** @type {Array<{start: string, end: string}>} select用の時間帯リスト */
  let timeFrameList = [];

  /** @type {import("~/libs/commonTypes").TimeFramePreset} 編集対象のプリセット */
  let editPreset = {
    name: "",
    timeFrameList: [],
  };

  /** @type {number} 編集対象プリセットのインデックス */
  export let index;

  /** @type {boolean} 未入力チェック */
  let checkNotEntered = false;

  /**
   * ダイアログを開く
   */
  export function openDialog() {
    editPreset = {
      name: "",
      timeFrameList: [],
    };
    timeFrameList = [];
    checkNotEntered = false;

    if (index < presetList.length) {
      editPreset.name = presetList[index].name;
      for (let i = 0; i < presetList[index].timeFrameList.length; i++) {
        timeFrameList[i] = {
          start: presetList[index].timeFrameList[i].substring(0, 2),
          end: presetList[index].timeFrameList[i].substring(2, 4),
        };
      }
    } else {
      timeFrameList = [
        { start: "", end: "" },
        { start: "", end: "" },
        { start: "", end: "" },
      ];
    }

    dialogOpened = true;
  }

  /**
   * ダイアログを閉じる
   */
  function closeDialog() {
    dialogOpened = false;
  }
</script>

{#if dialogOpened}
  <div class="presetDialog">
    <Dialog
      bind:open={dialogOpened}
      scrimClickAction={mandatory ? "" : "close"}
      escapeKeyAction={mandatory ? "" : "close"}
      on:SMUIDialog:closed={onDialogClosedHandler}
      aria-labelledby="preset-dialog-title"
      aria-describedby="preset-dialog-content"
    >
      <Header>
        <Title id="preset-dialog-title">時間帯候補を追加する</Title>
        <IconButton
          action="close"
          ripple={false}
          class="material-icons"
          style="position: absolute; top: 5px; right: 5px;">close</IconButton
        >
      </Header>
      <Content id="preset-dialog-content">
        <div class="nameArea">
          <Textfield
            type="text"
            label="時間帯候補の名称"
            variant="outlined"
            bind:value={editPreset.name}
          />
        </div>
        <div class="timeFrameArea">
          <p class="label">時間帯</p>
          <ol>
            {#each timeFrameList as timeFrame, i}
              <li>
                <Select variant="outlined" bind:value={timeFrame.start}>
                  <Option hidden value=""></Option>
                  {#each CHOICES_OF_TIME as time}
                    {#if (i !== 0 && timeFrameList[i - 1].end !== "" ? time >= timeFrameList[i - 1].end : true) && (timeFrameList[i].end !== "" ? time < timeFrameList[i].end : true)}
                      <Option value={time}>{Number(time)}時</Option>
                    {/if}
                  {/each}
                </Select>
                <span class="hyphen">～</span>
                <Select variant="outlined" bind:value={timeFrame.end}>
                  <Option hidden value=""></Option>
                  {#each CHOICES_OF_TIME as time}
                    {#if (timeFrame.start !== "" ? time > timeFrame.start : true) && (i !== timeFrameList.length - 1 && timeFrameList[i + 1].start !== "" ? time <= timeFrameList[i + 1].start : true)}
                      <Option value={time}>{Number(time)}時</Option>
                    {/if}
                  {/each}
                </Select>
              </li>
            {/each}
          </ol>
          <div class="btnArea">
            <Button
              color="secondary"
              variant="outlined"
              on:click={() => {
                timeFrameList.splice(timeFrameList.length, 0, {
                  start: "",
                  end: "",
                });
                timeFrameList = timeFrameList;
              }}
            >
              <span class="material-icons md-18">add</span>
              <Label>増やす</Label>
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              on:click={() => {
                if (timeFrameList.length > 1) {
                  timeFrameList.splice(timeFrameList.length - 1, 1);
                  timeFrameList = timeFrameList;
                }
              }}
              disabled={timeFrameList.length === 1 ? "disabled" : ""}
            >
              <span class="material-icons md-18">remove</span>
              <Label>減らす</Label>
            </Button>
          </div>
        </div>

        {#if checkNotEntered}
          <p class="caution">すべての欄を入力してください</p>
        {/if}

        <div class="buttonArea">
          <Button
            class="registerButton"
            color="secondary"
            variant="unelevated"
            on:click={() => {
              checkNotEntered = false;
              if (editPreset.name === "") {
                checkNotEntered = true;
              }
              timeFrameList.forEach((timeFrame) => {
                if (timeFrame.start === "" || timeFrame.end === "") {
                  checkNotEntered = true;
                }
              });
              if (checkNotEntered) {
                return;
              }

              let editedTimeFrameList = [];
              for (let i = 0; i < timeFrameList.length; i++) {
                editedTimeFrameList[i] =
                  timeFrameList[i].start + timeFrameList[i].end;
              }
              editPreset.timeFrameList = editedTimeFrameList;

              if (index < presetList.length) {
                presetList[index] = editPreset;
              } else {
                presetList.push(editPreset);
              }

              appContext.timeFramePresetList = presetList;
              appContext.store();

              dispatch("update", { presetList: presetList });
              closeDialog();
            }}
          >
            <Label>追加する</Label>
          </Button>
        </div>
      </Content>
    </Dialog>
  </div>
{/if}

<style lang="scss">
  .presetDialog {
    :global(.mdc-dialog__content) {
      display: flex;
      flex-direction: column;
      gap: 6px;
      overflow: visible;
    }
  }
  .nameArea {
    border-top: 1px solid #b6b6b6;
    color: #000;
    padding: 20px 0 10px;

    :global(.mdc-text-field) {
      width: 100%;
    }
  }
  .timeFrameArea {
    border-top: 1px solid #b6b6b6;
    color: #000;
    padding: 10px 0;

    :global(.mdc-select .mdc-select__anchor) {
      width: 120px;
    }
    :global(
        .mdc-select .mdc-select__menu .mdc-deprecated-list-item[hidden="true"]
      ) {
      display: none;
    }
    :global(.mdc-select .mdc-select__menu.mdc-menu-surface) {
      max-height: 200px !important;
    }
  }
  .timeFrameArea .label {
    margin-bottom: 10px;
  }
  .timeFrameArea ol li {
    display: flex;
    align-items: center;
    list-style: none;
    margin-top: 10px;
    text-align: center;
  }
  .timeFrameArea ol li .hyphen {
    width: 2em;
  }
  .timeFrameArea .btnArea {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;

    :global(.mdc-button) {
      font-size: 12px;
      width: 50%;
      height: auto;
    }
    :global(.mdc-button:not(:first-of-type)) {
      margin-left: 10px;
    }
  }
  .caution {
    color: #f00;
    margin-top: 10px;
  }
  .buttonArea {
    margin-top: 15px;

    :global(.registerButton) {
      width: 100%;
    }
  }
</style>
