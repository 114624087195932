import { Point, solve } from "salesman.js";

import { latlon2coordinate } from "~/libs/mapUtils";

/**
 * 配送ルートを計算する。
 * @param {{latitude: number, longitude: number}} origin 現在地の緯度・経度
 * @param {Array<import("~/libs/commonTypes").DeliveryPackage>} list 配達リスト
 * @param {{latitude: number, longitude: number}} [goalLatLon] ゴール地点の緯度・経度（省略時は現在地）
 */
export async function routing(origin, list, goalLatLon) {
  // 座標リストを作成
  const points = await initializePoints(origin, list);

  // ゴール地点が指定されている場合は、座標リストに追加
  let keepEnd = false;
  if (goalLatLon) {
    const goalCoordinates = latlon2coordinate(
      origin.latitude,
      origin.longitude,
      goalLatLon.latitude,
      goalLatLon.longitude,
    );
    points.push(new Point(goalCoordinates.x, goalCoordinates.y));
    keepEnd = true;
  }

  // アルゴリズムによって処理する
  const result = solve(points, 0.999999, undefined, undefined, keepEnd);
  console.log(
    "配達リストのソート（巡回セールスマン問題）:",
    "keepEnd=" + keepEnd,
    "points=" + JSON.stringify(points),
    "result=" + JSON.stringify(result),
  );

  // 順番を割りふる
  assignOrder(list, result);
}

/**
 * 初期地点から各宛先の座標を計算し、配列に格納する。
 * @param {{latitude: number, longitude: number}} origin 現在地の緯度・経度
 * @param {Array<import("~/libs/commonTypes").DeliveryPackage>} list 配達リスト
 * @returns {Promise<Array<Point>>} 座標リスト
 */
async function initializePoints(origin, list) {
  /** @type {Array<Point>} */
  let points = [];

  // 初期地点となる配送拠点の座標を追加する
  points.push(new Point(0, 0));

  for (const deliveryPackage of list) {
    try {
      const xy = latlon2coordinate(
        origin.latitude,
        origin.longitude,
        deliveryPackage.latlon.latitude,
        deliveryPackage.latlon.longitude,
      );
      points.push(new Point(xy.x, xy.y));
    } catch (error) {
      console.debug(error);
    }
  }
  console.debug(points);
  return points;
}

/**
 * 順番の割り振りを行う
 * @param {Array<import("~/libs/commonTypes").DeliveryPackage>} list 配達リスト
 * @param {number[]} result
 */
function assignOrder(list, result) {
  for (let i = 0; i < list.length; i++) {
    // index = 0は初期地点を表すため+1する
    let order = result.indexOf(i + 1);
    // 緯度経度が算出できなかった宛先はInginityとする
    if (order === -1) {
      list[i].order = Infinity;
    } else {
      list[i].order = order;
    }
  }
}
