<script>
  /** @type {number} 代引き金額 */
  export let cashOnDeliveryAmount;
  /** @type {string} 受取金額 */
  let receivedMoney = "";
  /** @type {number} おつり*/
  $: change =
    receivedMoney === ""
      ? 0
      : Number(receivedMoney.replace(/,/g, "")) - cashOnDeliveryAmount;

  /**
   * @typedef {object} ChangeSample
   * @property {number} receivedMoney
   * @property {number} change
   */

  /** @type {Array<ChangeSample>} おつりのよくあるパターン */
  const commonChangeTable = (() => {
    /** @type {Array<ChangeSample>} */
    let commonChangeTable = [];

    if (cashOnDeliveryAmount % 10000 !== 0) {
      // 代引き金額を10,000円で割った余りが0円でない（10,000円、20,000円、30,000円... の金額（= 10,000円ベースではお釣りが出ない金額）ではない）
      commonChangeTable.push(calcCommonChange(10000));
    }
    if (
      cashOnDeliveryAmount % 5000 !== 0 &&
      cashOnDeliveryAmount % 10000 < 5000
    ) {
      // 代引き金額を5,000円で割った余りが0円でない（5,000円、10,000円、15,000円... の金額（= 5,000円ベースではお釣りが出ない金額）ではない）
      // かつ、代引き金額を10,000円で割った余りが5,000円未満の場合（5,000円以上の場合は10,000円ベースと被る）
      commonChangeTable.push(calcCommonChange(5000));
    }
    if (
      cashOnDeliveryAmount % 1000 !== 0 &&
      cashOnDeliveryAmount % 5000 < 4000 &&
      cashOnDeliveryAmount % 10000 < 9000
    ) {
      // 代引き金額を1,000円で割った余りが0円でない（1,000円、2,000円、3,000円... の金額（= 1,000円ベースではお釣りが出ない金額）ではない）
      // かつ、代引き金額を5,000円で割った余りが4,000円未満（4,000円以上の場合は5,000円ベースと被る）
      // かつ、代引き金額を10,000円で割った余りが9,000円未満の場合（9,000円以上の場合は10,000円ベースと被る）
      commonChangeTable.push(calcCommonChange(1000));
    }
    if (
      cashOnDeliveryAmount % 100 !== 0 &&
      cashOnDeliveryAmount % 1000 < 900 &&
      cashOnDeliveryAmount % 5000 < 4900 &&
      cashOnDeliveryAmount % 10000 < 9900
    ) {
      // 10円単位の端数あり
      commonChangeTable.push(calcCommonChange(100));
    }

    return commonChangeTable;
  })();

  /**
   * 指定したベース金額でのよくある受取金額・お釣りのセットを返す
   * @param {number} base ベース金額
   * @returns {ChangeSample} よくある受取金額・お釣りのセット
   */
  function calcCommonChange(base) {
    const receivedMoneySample = Math.ceil(cashOnDeliveryAmount / base) * base;
    const changeSample = receivedMoneySample - cashOnDeliveryAmount;
    return { receivedMoney: receivedMoneySample, change: changeSample };
  }
</script>

<div class="changeCalculationArea">
  <div class="title">
    <p>おつりを計算する</p>
  </div>
  <div class="calc">
    <div class="cell">
      <p class="label">受取金額</p>
      <p class="amount">
        <input inputmode="numeric" bind:value={receivedMoney} maxlength="5" />円
      </p>
    </div>
    <p class="symbol" style="padding-top: 14px;">&minus;</p>
    <div class="centerCell">
      <p class="label">代引き金額</p>
      <p class="amount">
        {cashOnDeliveryAmount.toLocaleString()}円
      </p>
    </div>
    <p class="symbol" style="padding-top: 17px;">&equals;</p>
    <div class="cell">
      <p class="label">おつり</p>
      {#if receivedMoney === ""}
        <p class="amount"><span class="output">&emsp;&emsp;&emsp;</span>円</p>
      {:else if change < 0}
        <p class="amount">
          <span class="output">&emsp;&emsp;&emsp;</span>円
        </p>
      {:else}
        <p class="amount">
          <span class="output">{change.toLocaleString()}</span>円
        </p>
      {/if}
    </div>
  </div>

  {#if commonChangeTable.length !== 0}
    <div class="commonChangeTable">
      <p class="tableTitle">よくあるパターン</p>
      <div class="commonPatternsContainer">
        <div class="commonPatternsArea">
          {#each commonChangeTable as data}
            <div class="commonPattern">
              <p class="amountLine">
                <span class="amountTitle">受</span><span
                  >{data.receivedMoney.toLocaleString()}円</span
                >
              </p>
              <p class="amountLine">
                <span class="amountTitle">釣</span><span
                  >{data.change.toLocaleString()}円</span
                >
              </p>
            </div>
          {/each}
        </div>
      </div>
    </div>
  {/if}
</div>

<style>
  .changeCalculationArea input {
    font-size: 16px;
  }

  .title {
    position: relative;
    margin: 0 0 8px 4px;
    display: flex;
    font-weight: bold;
  }

  .calc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 5px 12px 5px;
  }

  .cell {
    width: 30%;
  }

  .centerCell {
    width: fit-content;
  }

  .symbol {
    font-size: larger;
    width: 5%;
  }

  .label {
    color: #018786;
    font-size: smaller;
    margin-bottom: 2px;
    padding: 0;
    text-align: left;
  }

  input {
    width: 60px;
    text-align: right;
  }

  .amount {
    font-size: larger;
    width: 100%;
  }

  .amount .output {
    border-bottom: 1px solid #018786;
    box-sizing: border-box;
    display: inline-block;
    margin-right: 3px;
    padding: 0 3px;
    text-align: right;
    width: calc(100% - 1em - 3px);
  }

  .commonChangeTable {
    margin: 0 4px 4px 4px;
  }

  .tableTitle {
    text-align: left;
    color: #018786;
    font-size: smaller;
    padding-bottom: 5px;
  }

  .commonPatternsContainer {
    width: 100%;
    overflow-x: auto;
  }

  .commonPatternsArea {
    width: max-content;
    display: flex;
    justify-content: center;
    gap: 5px;
    flex-wrap: nowrap;
  }

  .commonPattern {
    width: fit-content;
    border: 1px solid #ddd;
    padding: 6px 6px 0;
    border-radius: 3px;
    flex: 0 0 auto;
  }

  .amountLine {
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
    gap: 2px;
  }

  .amountTitle {
    color: #fff;
    background-color: #018786;
    border-radius: 2px;
  }
</style>
