<svg
  width="432.09"
  height="543.21"
  version="1.1"
  viewBox="0 0 432.09 543.21"
  xmlns="http://www.w3.org/2000/svg"
  class="svg-icons-front-camera"
>
  <defs>
    <clipPath id="clipPath198">
      <path d="m4e3 2222.2h-4e3v-2222.2h4e3z" />
    </clipPath>
    <clipPath id="clipPath204">
      <path d="m.060059 2222.3h4e3v-2222.3h-4e3z" />
    </clipPath>
  </defs>
  <g transform="matrix(1.3333 0 0 -1.3333 -1905.3 2410.5)">
    <g clip-path="url(#clipPath198)">
      <g clip-path="url(#clipPath204)">
        <g transform="translate(1632.7,534.22)">
          <g transform="translate(-120.37,277.78)">
            <g transform="translate(63.23,81.74)">
              <g transform="translate(-137.3,-137.3)">
                <g transform="translate(-26.19,137.3)">
                  <g transform="translate(128.04,-128.04)">
                    <g transform="translate(-46.3,-9.26)">
                      <g transform="translate(92.6,55.56)">
                        <g transform="translate(-166.67)">
                          <g transform="translate(1125.5 -263.89)">
                            <g transform="translate(-129.63,9.26)">
                              <g transform="translate(18.52,231.48)">
                                <g transform="translate(18.52,-157.41)">
                                  <g transform="translate(92.59,-9.26)">
                                    <g transform="translate(0 880.14)">
                                      <g transform="translate(0,-64.815)">
                                        <g transform="translate(-129.63,9.259)">
                                          <g
                                            transform="translate(1034.4 55.556)"
                                          >
                                            <g
                                              transform="translate(-296.29,-37.038)"
                                            >
                                              <g
                                                transform="translate(111.11 194.44)"
                                              >
                                                <g
                                                  transform="translate(231.48 -843.1)"
                                                >
                                                  <g
                                                    transform="translate(-9.26,-129.63)"
                                                  >
                                                    <g
                                                      transform="translate(-194.44,64.81)"
                                                    >
                                                      <g
                                                        transform="translate(27.77,-111.11)"
                                                      >
                                                        <g
                                                          transform="translate(111.12,-27.78)"
                                                        >
                                                          <g
                                                            transform="translate(-2676.7 870.88)"
                                                          >
                                                            <g
                                                              transform="translate(-148.15 268.52)"
                                                            >
                                                              <g
                                                                transform="translate(185.18 -101.85)"
                                                              >
                                                                <g
                                                                  transform="translate(0,-64.814)"
                                                                >
                                                                  <g
                                                                    transform="translate(850.19 -101.85)"
                                                                  >
                                                                    <path
                                                                      d="m0 0v-46.296c0-15.281-12.49-27.778-27.77-27.778h-148.15c-15.28 0-27.78 12.497-27.78 27.778v333.33c0 15.281 12.5 27.778 27.78 27.778h148.15c15.28 0 27.77-12.497 27.77-27.778v-64.814"
                                                                      fill="none"
                                                                      stroke="#000"
                                                                      stroke-miterlimit="10"
                                                                      stroke-width="18.518"
                                                                    />
                                                                    <g
                                                                      transform="translate(-129.63,-37.037)"
                                                                    >
                                                                      <path
                                                                        d="m0 0h55.56"
                                                                        fill="none"
                                                                        stroke="#000"
                                                                        stroke-miterlimit="10"
                                                                        stroke-width="18.518"
                                                                      />
                                                                      <g
                                                                        transform="translate(166.67 203.7)"
                                                                      >
                                                                        <path
                                                                          d="m0 0h64.81v-122.81c0-8.882-7.19-16.081-16.08-16.081h-171.54c-8.88 0-16.08 7.199-16.08 16.081v122.81h64.82l9.26 27.777h55.55z"
                                                                          fill="none"
                                                                          stroke="#000"
                                                                          stroke-miterlimit="10"
                                                                          stroke-width="18.518"
                                                                        />
                                                                        <g
                                                                          transform="translate(0,-64.814)"
                                                                        >
                                                                          <path
                                                                            d="m0 0c0-20.456-16.59-37.038-37.04-37.038-20.44 0-37.03 16.582-37.03 37.038 0 20.455 16.59 37.036 37.03 37.036 20.45 0 37.04-16.581 37.04-37.036z"
                                                                            fill="none"
                                                                            stroke="#000"
                                                                            stroke-miterlimit="10"
                                                                            stroke-width="18.518"
                                                                          />
                                                                        </g>
                                                                      </g>
                                                                    </g>
                                                                  </g>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
