<script>
  import { createEventDispatcher, onMount } from "svelte";

  import image1 from "~/assets/images/help/afterLogin1.png";
  import image2 from "~/assets/images/help/afterLoginAndroid.png";
  import image3 from "~/assets/images/help/afterLoginiOS.png";

  let contentsArea;
  let currentPage = 1;
  let dispatch = createEventDispatcher();

  onMount(() => {
    contentsArea.style.marginLeft = "+200%";
  });

  function previousPage() {
    if (currentPage > 1) {
      contentsArea.style.marginLeft = String((currentPage - 3) * -200) + "%";
      contentsArea.style.transition = ".2s";
      currentPage--;
    }
  }

  function nextPage() {
    if (currentPage < 2) {
      contentsArea.style.marginLeft =
        "-" + String((currentPage - 1) * 200) + "%";
      contentsArea.style.transition = ".2s";
      currentPage++;
    }
  }

  function confirm() {
    dispatch("confirm");
  }

  let os = detectOS();
  function detectOS() {
    const userAgent = navigator.userAgent;
    console.log(userAgent);
    if (/Android/i.test(userAgent)) {
      return "android";
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      return "ios";
    } else {
      return "other";
    }
  }
</script>

<!-- タイトルエリア -->
<div class="titleArea">
  <p>位置情報の利用と<br />通知の許可について</p>
</div>
<!-- コンテンツ表示エリア -->
<div class="helpContentsArea" bind:this={contentsArea}>
  <div class="helpContent">
    <img class="helpContentImage" src={image1} alt="helpContentImage" />
    <div class="messaggeArea">
      <p>
        置ToCアプリでは、スムーズな<br />
        配送業務をサポートするため、<br />
        <span class="strong">位置情報と通知</span>を利用します。<br />
      </p>
    </div>
  </div>
  <div class="helpContent">
    {#if os === "android"}
      <img class="helpContentImage2" src={image2} alt="helpContentImage" />
    {:else}
      <img class="helpContentImage3" src={image3} alt="helpContentImage" />
    {/if}
    <div class="messaggeArea">
      <p>
        利用確認のダイアログが表示<br />
        されましたら、<span class="strong">『許可』を選択</span><br />
        いただくようお願いいたします。
      </p>
    </div>
    <div class="confirmButtonArea">
      <button class="confirmButton" on:click={confirm}>OK</button>
    </div>
  </div>
</div>
<!-- ページ管理エリア -->
<div class="pageManageArea">
  <div class="pageButtonArea">
    <button class="pageButton" id="previousButton" on:click={previousPage}>
      <span class="dli-chevron-left"></span>
    </button>
  </div>
  <div class="pageStatusArea">
    <div class="pageStatus">
      <div class="page" class:currentPage={currentPage == 1}></div>
      <div class="page" class:currentPage={currentPage == 2}></div>
    </div>
  </div>
  <div class="pageButtonArea">
    <button class="pageButton" id="nextButton" on:click={nextPage}>
      <span class="dli-chevron-right"></span>
    </button>
  </div>
</div>

<style>
  /* タイトルエリア */
  .titleArea {
    margin: 35px 0 30px;
    text-align: center;
    line-height: 28px;
    font-size: 22px;
    font-weight: bold;
  }
  /* コンテンツ表示エリア */
  .helpContentsArea {
    position: relative;
    display: flex;
    width: 300%;
    height: 320px;
  }
  .helpContent {
    width: 33.33%;
    text-align: center;
  }
  .helpContentImage {
    margin: 15px 0;
    width: 260px;
    height: 150px;
  }
  .helpContentImage2,
  .helpContentImage3 {
    margin: 15px 0;
    width: 300px;
    height: 120px;
  }
  .messaggeArea {
    margin: 25px 0;
    font-size: 17px;
    line-height: 25px;
  }
  .strong {
    background: linear-gradient(transparent 50%, #83d3b8 80%);
  }
  .confirmButtonArea {
    position: relative;
    width: 100%;
    height: 20%;
    text-align: center;
  }
  .confirmButton {
    position: absolute;
    transform: translateX(-50%);
    display: inline-block;
    width: 130px;
    height: 40px;
    font-size: 18px;
    color: #fff;
    background-color: #018786;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  /* ページ管理エリア */
  .pageManageArea {
    width: 100%;
    height: 85px;
    display: flex;
  }
  .pageButtonArea {
    position: relative;
    width: 20%;
    vertical-align: auto;
  }
  .pageButton {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border: none;
    background: transparent;
    cursor: pointer;
  }
  .pageButton :focus {
    background-color: white;
  }
  .dli-chevron-left {
    display: inline-block;
    vertical-align: middle;
    color: #018786;
    line-height: 1;
    width: 2em;
    height: 2em;
    border: 0.4em solid currentColor;
    border-left: 0;
    border-bottom: 0;
    box-sizing: border-box;
    transform: translateX(25%) rotate(-135deg);
  }
  .dli-chevron-right {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    color: #018786;
    width: 2em;
    height: 2em;
    border: 0.4em solid currentColor;
    border-left: 0;
    border-bottom: 0;
    box-sizing: border-box;
    transform: translateX(-25%) rotate(45deg);
  }
  .pageStatusArea {
    position: relative;
    width: 60%;
    display: flex;
  }
  .pageStatus {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 20px;
    justify-content: center;
    display: flex;
  }
  .page {
    width: 12px;
    height: 12px;
    margin: 0 10px;
    border-radius: 10px;
    background-color: #d8d8d8;
  }
  .currentPage {
    background-color: #018786;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
  }
</style>
