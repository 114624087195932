<script>
  import { createEventDispatcher, onMount } from "svelte";

  import image1 from "~/assets/images/help/pickupAndSort1.png";
  import image2 from "~/assets/images/help/pickupAndSort2.png";
  import image3 from "~/assets/images/help/pickupAndSort3.png";
  import image4 from "~/assets/images/help/pickupAndSort4.png";
  import image5 from "~/assets/images/help/pickupAndSort5.png";
  import image6 from "~/assets/images/help/pickupAndSort6.png";
  import image7 from "~/assets/images/help/pickupAndSort7.png";
  import image8 from "~/assets/images/help/pickupAndSort8.png";

  let contentsArea;
  let currentPage = 1;
  let dispatch = createEventDispatcher();

  onMount(() => {
    contentsArea.style.marginLeft = "+800%";
  });

  function previousPage() {
    if (currentPage > 1) {
      contentsArea.style.marginLeft = String((currentPage - 6) * -200) + "%";
      contentsArea.style.transition = ".2s";
      currentPage--;
    }
  }

  function nextPage() {
    if (currentPage < 8) {
      contentsArea.style.marginLeft = String((currentPage - 4) * -200) + "%";
      contentsArea.style.transition = ".2s";
      currentPage++;
    }
  }

  function confirm() {
    dispatch("confirm");
  }
</script>

<!-- タイトルエリア -->
<div class="titleArea">
  <p>荷受け・輸送の流れ</p>
</div>
<!-- サブタイトルエリア -->
<div class="subtitleArea">
  {#if currentPage <= 6}
    <p>①荷受けの流れ</p>
  {:else if currentPage === 7}
    <p>②輸送</p>
  {:else if currentPage === 8}
    <p>③荷下ろしの流れ</p>
  {/if}
</div>
<!-- コンテンツ表示エリア -->
<div class="helpContentsArea" bind:this={contentsArea}>
  <div class="helpContent2">
    <img class="helpContentImage2" src={image1} alt="helpContentImage" />
    <div class="messaggeArea">
      <p>
        以下のルールに従って、荷物をスキャンしながら<br
        />カゴ車への積込みを行います。<br />
        ①異なる輸送先の荷物は同じカゴ車に入れない<br />
        ②スキャン結果の確定はカゴ車単位で行う
      </p>
    </div>
  </div>
  <div class="helpContent">
    <img class="helpContentImage" src={image2} alt="helpContentImage" />
  </div>
  <div class="helpContent">
    <img class="helpContentImage" src={image3} alt="helpContentImage" />
  </div>
  <div class="helpContent">
    <img class="helpContentImage" src={image4} alt="helpContentImage" />
  </div>
  <div class="helpContent">
    <img class="helpContentImage" src={image5} alt="helpContentImage" />
  </div>
  <div class="helpContent">
    <img class="helpContentImage" src={image6} alt="helpContentImage" />
  </div>
  <div class="helpContent">
    <img class="helpContentImage" src={image7} alt="helpContentImage" />
  </div>
  <div class="helpContent">
    <img class="helpContentImageSmall" src={image8} alt="helpContentImage" />
    <div class="confirmButtonArea">
      <button class="confirmButton" on:click={confirm}>OK</button>
    </div>
  </div>
</div>
<!-- ページ管理エリア -->
<div class="pageManageArea">
  <div class="pageButtonArea">
    <button class="pageButton" id="previousButton" on:click={previousPage}>
      <span class="dli-chevron-left"></span>
    </button>
  </div>
  <div class="pageStatusArea">
    <div class="pageStatus">
      <div class="page" class:currentPage={currentPage == 1}></div>
      <div class="page" class:currentPage={currentPage == 2}></div>
      <div class="page" class:currentPage={currentPage == 3}></div>
      <div class="page" class:currentPage={currentPage == 4}></div>
      <div class="page" class:currentPage={currentPage == 5}></div>
      <div class="page" class:currentPage={currentPage == 6}></div>
      <div class="page" class:currentPage={currentPage == 7}></div>
      <div class="page" class:currentPage={currentPage == 8}></div>
    </div>
  </div>
  <div class="pageButtonArea">
    <button class="pageButton" id="nextButton" on:click={nextPage}>
      <span class="dli-chevron-right"></span>
    </button>
  </div>
</div>

<style>
  /* タイトルエリア */
  .titleArea {
    margin: 35px 0 24px;
    text-align: center;
    line-height: 28px;
    font-size: 22px;
    font-weight: bold;
  }
  /* サブタイトルエリア */
  .subtitleArea {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 8px;
  }
  /* コンテンツ表示エリア */
  .helpContentsArea {
    position: relative;
    display: flex;
    width: 900%;
    height: 410px;
  }
  .helpContent {
    width: 11.11%;
    text-align: center;
  }
  .helpContent2 {
    width: 11.11%;
    text-align: center;
    margin: auto 0;
  }
  .helpContentImage {
    width: 310px;
    height: 400px;
    border-radius: 10px;
  }
  .helpContentImage2 {
    margin-top: 20px;
    width: 310px;
    height: auto;
  }
  .helpContentImageSmall {
    width: 300px;
    height: 360px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .messaggeArea {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    font-size: 14px;
    line-height: 25px;
    text-align: left;
  }
  .confirmButtonArea {
    position: relative;
    width: 100%;
    height: 20%;
    text-align: center;
  }
  .confirmButton {
    position: absolute;
    transform: translateX(-50%);
    display: inline-block;
    width: 130px;
    height: 40px;
    font-size: 18px;
    color: #fff;
    background-color: #018786;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  /* ページ管理エリア */
  .pageManageArea {
    width: 100%;
    height: 85px;
    display: flex;
  }
  .pageButtonArea {
    position: relative;
    width: 20%;
    vertical-align: auto;
  }
  .pageButton {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border: none;
    background: transparent;
    cursor: pointer;
  }
  .pageButton :focus {
    background-color: white;
  }
  .dli-chevron-left {
    display: inline-block;
    vertical-align: middle;
    color: #018786;
    line-height: 1;
    width: 2em;
    height: 2em;
    border: 0.4em solid currentColor;
    border-left: 0;
    border-bottom: 0;
    box-sizing: border-box;
    transform: translateX(25%) rotate(-135deg);
  }
  .dli-chevron-right {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    color: #018786;
    width: 2em;
    height: 2em;
    border: 0.4em solid currentColor;
    border-left: 0;
    border-bottom: 0;
    box-sizing: border-box;
    transform: translateX(-25%) rotate(45deg);
  }
  .pageStatusArea {
    position: relative;
    width: 60%;
    display: flex;
  }
  .pageStatus {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 20px;
    justify-content: center;
    display: flex;
  }
  .page {
    width: 12px;
    height: 12px;
    margin: 0 6px;
    border-radius: 10px;
    background-color: #d8d8d8;
  }
  .currentPage {
    background-color: #018786;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
  }
</style>
