import { cubicInOut } from "svelte/easing";
import { tweened } from "svelte/motion";
import TinyGesture from "tinygesture";

/**
 * @callback onPanMoveCallback
 * @param {number} touchMoveX
 *
 * @callback onPanEndCallback
 * @param {number} touchMoveX
 * @returns {number} left offet (px)
 */

/**
 * @param {HTMLElement} node
 * @param {{
 *   allowLeftSwipe?: boolean,
 *   allowRightSwipe?: boolean,
 *   scrollableParent?: {element: HTMLElement, overflowY: string},
 *   onPanMove?: onPanMoveCallback,
 *   onPanEnd?: onPanEndCallback,
 * }} options
 * @returns {import("svelte/action").ActionReturn}
 */
export default function swipeable(
  node,
  {
    allowLeftSwipe = false,
    allowRightSwipe = false,
    scrollableParent,
    onPanMove,
    onPanEnd,
  },
) {
  const gesture = new TinyGesture(node);

  /** @type {number} */
  let animationFrame;

  /** 左右オフセット調整用のSvelte Tweened Store */
  const leftOffset = tweened(0, {
    duration: 300,
    easing: cubicInOut,
  });
  const leftOffsetUnsubscriber = leftOffset.subscribe((value) => {
    node.style.left = value + "px";
  });

  /** 縦スクロール連携調整用のコンテキスト情報 */
  const scrollContext = {
    lastScrollTime: 0,
    scrollHandler: () => {
      scrollContext.lastScrollTime = Date.now();
    },
    isInScrolling: () => {
      return Date.now() - scrollContext.lastScrollTime < 200;
    },
  };
  if (scrollableParent) {
    scrollableParent.element.addEventListener(
      "scroll",
      scrollContext.scrollHandler,
    );
  }

  node.style.transition =
    (node.style.transition ? node.style.transition + ", " : "") +
    "opacity .3s ease";

  gesture.on("panmove", () => {
    if (animationFrame) {
      return;
    }
    animationFrame = window.requestAnimationFrame(() => {
      if (!allowRightSwipe && gesture.touchMoveX > 0) {
        // 右スワイプ無効
        return;
      } else if (!allowLeftSwipe && gesture.touchMoveX < 0) {
        // 左スワイプ無効
        return;
      } else if (Math.abs(gesture.touchMoveX) < 5) {
        // iOSの感度が非常に高いためあそびを設ける（5px未満の移動は無視）
        return;
      }

      if (scrollableParent) {
        if (scrollContext.isInScrolling()) {
          // スクロール中は無効
          return;
        }
        scrollableParent.element.style.overflowY = "hidden";
      }

      leftOffset.set(gesture.touchMoveX, { duration: 0 });
      animationFrame = null;

      if (onPanMove) {
        onPanMove(gesture.touchMoveX);
      }
    });
  });

  gesture.on("panend", () => {
    if (animationFrame != null) {
      window.cancelAnimationFrame(animationFrame);
    }
    animationFrame = null;

    if (scrollableParent) {
      scrollableParent.element.style.overflowY = scrollableParent.overflowY;
    }

    let lastLeft = 0;
    if (onPanEnd && !scrollContext.isInScrolling()) {
      lastLeft = onPanEnd(gesture.touchMoveX);
    }
    leftOffset.set(lastLeft);
  });

  return {
    destroy() {
      if (scrollableParent) {
        scrollableParent.element.removeEventListener(
          "scroll",
          scrollContext.scrollHandler,
        );
        scrollableParent.element.style.overflowY = scrollableParent.overflowY;
      }

      if (animationFrame != null) {
        window.cancelAnimationFrame(animationFrame);
      }
      leftOffsetUnsubscriber();
      gesture.destroy();
    },
  };
}
