<svelte:options accessors={true} />

<script>
  import CircularProgress from "@smui/circular-progress";
  import { onDestroy, onMount } from "svelte";

  /** 読込中に表示するメッセージ（HTMLタグ可） @type {string} */
  export let message;

  /** @type {HTMLDivElement} */
  let contentsElement;
  /** @type {HTMLElement} */
  let activeElement;

  onMount(() => {
    activeElement = /** @type {HTMLElement} */ (document.activeElement);
    contentsElement?.focus?.();
  });

  onDestroy(() => {
    if (activeElement && activeElement.checkVisibility?.() !== false) {
      activeElement.focus?.();
    }
  });
</script>

<svelte:document
  on:keydown|capture={(/** @type {KeyboardEvent} */ event) => {
    event.preventDefault();
    return false;
  }}
/>

<div class="overlay" />
<div bind:this={contentsElement} class="contents" tabindex="-1">
  <CircularProgress indeterminate />
  <p class="message">
    {#if message}
      <!-- 内部で設定した文言しか埋め込まれないためエスケープ処理不要 -->
      {@html message}
    {:else}
      読み込み中です<br />しばらくお待ちください
    {/if}
  </p>
</div>

<style lang="scss">
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    z-index: 2147483645;
  }

  .contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: fixed;
    top: 50%;
    width: 100%;
    padding: 40px 0;
    background: #fff;
    border-top: #eee solid 0.5px;
    border-bottom: #eee solid 0.5px;
    color: #333;
    transform: translateY(-50%);
    z-index: 2147483646;
    opacity: 0;
    animation: fadeIn-keyframes 150ms ease-in forwards;
    will-change: opacity;

    :global(.mdc-circular-progress) {
      width: 60px;
      height: 60px;
    }
  }

  .message {
    font-size: 1em;
    line-height: 1.3;
    text-align: center;
  }

  @keyframes fadeIn-keyframes {
    100% {
      opacity: 1;
    }
  }
</style>
