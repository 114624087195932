<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 136.77">
  <defs>
    <style>
      .cls-1 {
        fill: #4688f1;
      }

      .cls-1,
      .cls-2,
      .cls-3,
      .cls-4,
      .cls-5 {
        stroke-width: 0px;
      }

      .cls-2 {
        fill: #3aa757;
      }

      .cls-3 {
        fill: #fabb2d;
      }

      .cls-4 {
        fill: #e8453c;
      }

      .cls-5 {
        fill: #2376e5;
      }
    </style>
  </defs>
  <g>
    <g>
      <path
        class="cls-1"
        d="m37.49,39.61c3-3.6,7.46-5.95,12.51-5.95,9.02,0,16.33,7.31,16.33,16.33,0,4.1-1.56,7.81-4.06,10.68l.05.04,30.45-36.52c-5.75-9.51-14.55-16.92-25.07-20.91l-30.26,36.28.05.05Z"
      />
      <path
        class="cls-2"
        d="m62.31,60.72h0l-36.15,43.36c6.88,8.83,13.25,18.03,18.82,27.74.63,1.09,1.18,2.08,1.62,2.91,1.45,2.73,5.35,2.73,6.8,0,.44-.83.99-1.82,1.62-2.91,5.94-10.36,12.78-20.16,20.19-29.52,9.62-12.16,24.79-34.19,24.79-52.29,0-9.45-2.67-18.26-7.23-25.8l-30.45,36.52Z"
      />
      <path
        class="cls-5"
        d="m67.7,3.28C62.19,1.19,56.24,0,50,0c-15.33,0-29.03,6.91-38.2,17.77l25.64,21.8L67.7,3.28Z"
      />
      <path
        class="cls-3"
        d="m62.27,60.68c-2.99,3.44-7.35,5.66-12.27,5.66-9.02,0-16.33-7.31-16.33-16.33,0-3.97,1.47-7.56,3.82-10.39l-.05-.05L7.37,75.63c5.2,10.34,12.14,19.99,17.42,26.66.47.59.91,1.19,1.37,1.79l36.15-43.36-.05-.04Z"
      />
      <path
        class="cls-4"
        d="m11.8,17.77C4.45,26.48,0,37.71,0,50c0,8.16,3.1,17.13,7.37,25.62l30.07-36.06L11.8,17.77Z"
      />
    </g>
  </g>
</svg>
