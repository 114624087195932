<script>
  import Button, { Label } from "@smui/button";
  import Dialog, { Actions, Content, Header, Title } from "@smui/dialog";
  import IconButton from "@smui/icon-button";
  import Paper from "@smui/paper";
  import Textfield from "@smui/textfield";
  import CharacterCounter from "@smui/textfield/character-counter";
  import { getContext, onDestroy } from "svelte";
  import { _ } from "svelte-i18n";
  import { UAParser } from "ua-parser-js";

  import { CONTEXT_KEY_APP } from "~/libs/constants";
  import { Html5QrCodeScanner, ZBarQrCodeScanner } from "~/libs/qrCodeScanner";
  import { toast } from "~/libs/toast";
  import { verifyTrackingNumberChecksum } from "~/libs/trackingNumberUtils";

  /**
   * @type {import("~/libs/qrCodeScanner").onScanSuccessCallback}
   */
  export let onScanSuccessHandler;

  /**
   * @type {import("~/libs/qrCodeScanner").onScanErrorCallback}
   */
  export let onScanErrorHandler;

  /**
   * @type {import("~/libs/qrCodeScanner").onVideoResizedCallback}
   */
  export let onVideoResizedHandler = () => {};

  /** @type {boolean} 送り状番号を手入力するフォームの表示有無 */
  export let enableInputForm;

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {Html5QrCodeScanner | ZBarQrCodeScanner} */
  const qrCodeScanner = appContext.useOpenCvQrCodeScanner
    ? new ZBarQrCodeScanner(
        onScanSuccessHandler,
        onScanErrorHandler,
        onPauseStatusChangeHandler,
        onVideoResizedHandler,
      )
    : new Html5QrCodeScanner(
        onScanSuccessHandler,
        onScanErrorHandler,
        onPauseStatusChangeHandler,
        onVideoResizedHandler,
      );

  /** @type {boolean} */
  let paused = qrCodeScanner.paused;
  /** @type {boolean} */
  let useBackCamera = true;

  /**
   * 追跡番号入力ダイアログの開閉フラグ
   * @type {boolean}
   */
  let inputDialogOpend = false;
  $: {
    if (!inputDialogOpend) {
      onCloseInputDialog();
    }
    qrCodeScanner.pauseOrResumeScanning(inputDialogOpend);
  }
  function onCloseInputDialog() {
    inputTrackingNumber = "";
  }

  /**
   * 追跡番号のテキストボックスの値
   * @type {string}
   */
  let inputTrackingNumber = "";
  let isAcceptableTrackingNumber = false;
  $: isAcceptableTrackingNumber =
    inputTrackingNumber.match(/^[0-9]{12}$/) &&
    verifyTrackingNumberChecksum(inputTrackingNumber);

  /** @type {Promise<void>} QRコードスキャナ初期化処理（onMount前） */
  const qrCodeScannerInitBeforeMountPromise = qrCodeScanner.initBeforeMount();

  /** QRコードスキャナ初期化処理（onMount後） */
  const qrCodeScannerInitAfterMount = (() => {
    let initialized = false;
    return () => {
      if (!initialized) {
        initialized = true;
        qrCodeScanner.initAfterMount();
      }
    };
  })();

  onDestroy(() => {
    stopScanning();
  });

  /**
   * スキャンを開始する。
   * @param {() => void} [startedCallback] スキャン開始後に呼び出すコールバック関数
   */
  export async function startScanning(startedCallback) {
    try {
      await qrCodeScannerInitBeforeMountPromise;
      qrCodeScannerInitAfterMount();
      await qrCodeScanner.startScanning(
        appContext,
        useBackCamera,
        startedCallback,
      );
    } catch (error) {
      console.error(error); // use non-logger explicitly
      toast.error($_("errors.failedToStartCamera") + error.message);
    }
  }

  /**
   * スキャンを停止する。
   */
  export async function stopScanning() {
    await qrCodeScanner.stopScanning();
  }

  /**
   * QRコードの読み取りを一時停止または再開します。
   * @param {boolean} pause true：一時停止、false：再開
   */
  export function pauseOrResumeScanning(pause) {
    qrCodeScanner.pauseOrResumeScanning(pause);
  }

  /**
   * @returns {Html5QrCodeScanner | ZBarQrCodeScanner}
   */
  export function getScannerInstance() {
    return qrCodeScanner;
  }

  /**
   * @param {boolean} currentlyPaused
   */
  function onPauseStatusChangeHandler(currentlyPaused) {
    paused = currentlyPaused;
  }

  function onClickPauseAndResumeButton() {
    qrCodeScanner.pauseOrResumeScanning();
  }

  async function onClickRotateCameraButton() {
    useBackCamera = !useBackCamera;
    console.log("Rotate Camera", useBackCamera);
    await qrCodeScanner.stopScanning();
    await qrCodeScanner.startScanning(appContext, useBackCamera);
  }

  function onClickInputTrackingNumberButton() {
    inputDialogOpend = true;
  }

  function onClickInputDialogOkButton() {
    console.log("Input tracking number", inputTrackingNumber);
    qrCodeScanner.onScanSuccessHandler(inputTrackingNumber, false, null);
  }
</script>

<svelte:window on:orientationchange={qrCodeScanner.resizeIfNeeded} />

<div id="qrCodeScannerArea" bind:this={qrCodeScanner.qrCodeScannerArea}>
  <div class="previewRegion" bind:this={qrCodeScanner.previewRegion}>
    <video muted playsinline bind:this={qrCodeScanner.video} />
    <div class="shadedRegion" bind:this={qrCodeScanner.shadedRegion}>
      <div style="width: 40px; height: 5px; top: -5px; left: 0" />
      <div style="width: 40px; height: 5px; top: -5px; right: 0;" />
      <div style="width: 40px; height: 5px; bottom: -5px; left: 0;" />
      <div style="width: 40px; height: 5px; bottom: -5px; right: 0;" />
      <div style="width: 5px; height: 45px; top: -5px; left: -5px;" />
      <div style="width: 5px; height: 45px; bottom: -5px; left: -5px;" />
      <div style="width: 5px; height: 45px; top: -5px; right: -5px;" />
      <div style="width: 5px; height: 45px; bottom: -5px; right: -5px;" />
    </div>
    <div class="qrCodeScannerButtonArea">
      <IconButton
        class="material-icons md-light pauseAndResumeButton"
        on:click={onClickPauseAndResumeButton}
        >{paused ? "play_circle" : "pause_circle"}</IconButton
      >
      <IconButton
        class="material-icons md-light rotateCameraButton"
        on:click={onClickRotateCameraButton}>flip_camera_ios</IconButton
      >
      {#if enableInputForm}
        <IconButton
          class="material-icons md-light inputTrackingNumberButton"
          on:click={onClickInputTrackingNumberButton}>keyboard</IconButton
        >
      {/if}
    </div>
  </div>
  <Dialog bind:open={inputDialogOpend}>
    <Header>
      <Title>送り状番号の入力</Title>
      <IconButton
        action="close"
        class="material-icons"
        style="position: absolute; top: 5px; right: 5px;">close</IconButton
      >
    </Header>
    <Content>
      <div class="inputDialogDescription">
        QRコードが読み取れない場合は送り状番号の手入力も可能です。トラブル防止のため、送り状番号を手入力したことがあなたのお名前と共に記録されます。
      </div>
      <Textfield
        variant="outlined"
        label="送り状番号"
        style="width: 100%;"
        required
        input$minlength={12}
        input$maxlength={12}
        input$pattern="[0-9]{'{'}12{'}'}"
        input$inputmode="numeric"
        bind:value={inputTrackingNumber}
      >
        <CharacterCounter slot="helper">0 / 12</CharacterCounter>
      </Textfield>
    </Content>
    <Actions>
      <Button
        color="secondary"
        variant="unelevated"
        disabled={!isAcceptableTrackingNumber}
        on:click={onClickInputDialogOkButton}
      >
        <Label>OK</Label>
      </Button>
    </Actions>
  </Dialog>
</div>
<div
  id="qrCodeScannerFallbackArea"
  bind:this={qrCodeScanner.qrCodeScannerFallbackArea}
>
  <Paper variant="unelevated">
    <Title>カメラの起動エラー</Title>
    <Content>
      <p>
        QRコードの読み取りにはカメラの使用許可が必要です。確認ダイアログが表示された場合は「許可」や「続行」を選択してください。
      </p>
      <p>
        ダイアログが表示されない場合は{UAParser(navigator.userAgent)
          ?.browser?.name?.replace(/^Mobile /, "")
          ?.replace("WebKit", "アプリ") ??
          "ブラウザ"}の設定でカメラへのアクセスが「拒否」や「許可しない」になっていないか確認のうえアプリケーションを再起動してお試しください。
      </p>
    </Content>
  </Paper>
</div>
<div id="retryRecommendedArea" bind:this={qrCodeScanner.retryRecommendedArea}>
  <Paper variant="unelevated">
    <Content>
      <p>
        カメラが起動しない場合、一度前の画面に戻ってから再度お試しください。
      </p>
    </Content>
  </Paper>
</div>

<canvas bind:this={qrCodeScanner.qrCodeCanvasElement} hidden />

<style lang="scss">
  #qrCodeScannerArea {
    display: none;
    pointer-events: none;

    .previewRegion {
      position: relative;
      width: 100%;
      text-align: center;
      margin: 0 auto;

      > video {
        display: block;
        width: 100%;
        object-fit: cover;
      }

      .shadedRegion {
        position: absolute;
        inset: 0;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.48);
        box-sizing: border-box;

        > div {
          position: absolute;
          background-color: #fff;
        }
      }

      :global(.shadedRegion.flash > div) {
        background-color: rgb(90, 193, 56);
      }

      .qrCodeScannerButtonArea {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 10;
        pointer-events: auto;

        :global(.material-icons) {
          font-size: 36px;
        }
      }
    }

    .inputDialogDescription {
      margin: 0 0 10px;
    }

    :global(.mdc-dialog) {
      z-index: 20;
    }
  }

  #qrCodeScannerFallbackArea,
  #retryRecommendedArea {
    display: none;
    padding: 5px 5px 0;
    margin: 0 auto;

    :global(.mdc-dialog__title) {
      text-align: center;
      color: #bd362f;
    }
    :global(.mdc-dialog__content p:nth-child(n + 2)) {
      margin-top: 10px;
    }
  }
</style>
