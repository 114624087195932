<script>
  import { Icon } from "@smui/button";
  import { liveQuery } from "dexie";

  import { initAudioContext } from "~/libs/audio";
  import { BackendApiName } from "~/libs/constants";
  import { db } from "~/libs/db";
  import pageRouter from "~/libs/pageRouter";
  import { displayOfflineModeHelp } from "~/libs/stores";
  import OnlineModeDialog from "~/pages/Offline/SwitchOnlineModeDialog.svelte";
  import ConfigurationDialog from "~/pages/OtherMenu/ConfigurationDialog.svelte";

  /**
   * 設定ダイアログコンポーネントのインスタンス
   * @type {ConfigurationDialog}
   */
  let configurationDialog;

  /**
   * オンラインモード切り替えダイアログコンポーネントのインスタンス
   * @type {OnlineModeDialog}
   */
  let onlineModeDialog;

  /**
   * 送信待ちリクエストの数
   */
  let numRequestsQueue = liveQuery(() => {
    return db.requestsQueue
      .filter((r) => r.backendApiName === BackendApiName.UPDATE_SHIPMENT_STATUS)
      .count();
  });
</script>

<footer>
  <div class="column active">
    <button
      on:click={() => {
        initAudioContext();
        pageRouter.moveToOfflineUpdate();
      }}
    >
      <Icon class="material-icons md-dark">assignment_turned_in</Icon><br />
      <span class="label">配達登録</span>
    </button>
  </div>
  <div class="column">
    <button
      on:click={() => {
        onlineModeDialog.openDialog();
      }}
    >
      <Icon class="material-icons md-dark">cloud_sync</Icon><br />
      <span class="label">送信</span>
    </button>
    {#if $numRequestsQueue > 0}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <div class="badgeNumBtn">
        {$numRequestsQueue}
      </div>
    {/if}
  </div>
  <div class="column">
    <button
      on:click={() => {
        configurationDialog.openDialog();
      }}
    >
      <Icon class="material-icons md-dark">settings</Icon><br />
      <span class="label">設定</span>
    </button>
  </div>
  <div class="column">
    <button
      on:click={() => {
        displayOfflineModeHelp.set(true);
      }}
    >
      <Icon class="material-icons md-dark">help_center</Icon><br />
      <span class="label">ヘルプ</span>
    </button>
  </div>
</footer>

<ConfigurationDialog bind:this={configurationDialog} />
<OnlineModeDialog bind:this={onlineModeDialog} />

<style lang="scss">
  footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 55px;
    min-height: 55px;
    padding-bottom: calc(env(safe-area-inset-bottom, 10px) - 10px);
    border-top: solid 0.5px #b2b2b2;
    background-color: #fff;
  }

  .column {
    position: relative;
    button {
      border: none;
      background: none;
      text-align: center;
      line-height: 1;

      :global(.material-icons) {
        font-size: 28px;
        color: var(--mdc-theme-secondary);
      }

      .label {
        font-size: 11px;
        color: var(--mdc-theme-secondary);
      }
    }

    &.active button {
      pointer-events: none;

      :global(.material-icons),
      .label {
        color: var(--mdc-theme-primary);
      }
    }

    .badgeNumBtn {
      position: absolute;
      width: 20px;
      height: 20px;
      top: -5px;
      left: 33px;
      font-size: 12px;
      z-index: 2;
      color: white;
      background-color: red;
      border-radius: 50%;
      text-align: center;
      line-height: 20px;
    }
  }
</style>
