<svg
  id="door"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 129.58 181.08"
  class="svg-icons-door"
  ><defs
    ><style>
      .door-cls-1 {
        fill: #203a72;
        stroke-width: 0px;
      }
    </style></defs
  ><g id="Layer_1"
    ><path
      class="door-cls-1"
      d="M122.49,16.64h-35.88V7.09c0-2.16-.98-4.2-2.67-5.55-1.69-1.34-3.9-1.84-6.01-1.36L5.5,16.82c-3.22.74-5.5,3.61-5.5,6.91v133.61c0,3.31,2.28,6.17,5.5,6.91l72.43,16.64c.53.12,1.06.18,1.59.18,1.59,0,3.15-.54,4.42-1.54,1.69-1.35,2.67-3.39,2.67-5.55v-9.55h35.88c3.92,0,7.09-3.18,7.09-7.09V23.73c0-3.92-3.18-7.09-7.09-7.09ZM59.94,99.54c-4.97,0-9-4.03-9-9s4.03-9,9-9,9,4.03,9,9-4.03,9-9,9ZM115.4,150.26h-28.79V30.83h28.79v119.43Z"
    /></g
  ></svg
>
