<script>
  import { QrHomeTypes } from "~/libs/constants";
  import pageRouter from "~/libs/pageRouter";
</script>

<div class="emptyListContent">
  <span class="material-icons icon">playlist_add_check</span>
  <p class="emptyText">配達が必要な荷物はありません</p>
  <button
    class="moveButton"
    on:click={() => {
      pageRouter.moveToQrHome(QrHomeTypes.OUT_FOR_DELIVERY);
    }}
  >
    配送センターから荷物を持ち出す
  </button>
</div>

<style lang="scss">
  .emptyListContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    .icon {
      font-size: 200px;
      color: #d9d9d9;
    }
    .emptyText {
      margin: -10px 0 45px;
    }
    .moveButton {
      margin-bottom: 50px;
      padding: 14px 30px;
      color: #fff;
      background-color: #018786;
      border: none;
      border-radius: 10px;
      font-size: 17px;
    }
  }
</style>
