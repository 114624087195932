<svg
  id="delivery"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 373.06 187.52"
  class="svg-icons-delivery"
  ><defs
    ><style>
      .delivery-cls-1 {
        fill: #14a0de;
      }
      .delivery-cls-1,
      .delivery-cls-2 {
        stroke-width: 0px;
      }
      .delivery-cls-2 {
        fill: #203a72;
      }
    </style></defs
  ><g id="Layer_1"
    ><path
      class="delivery-cls-2"
      d="M360.09,87.62l-38.9-5.2-22.32-39.01c-6.05-10.54-17.33-17.09-29.48-17.09h-42.55c-4.12,0-7.47,3.35-7.47,7.5v84.94h-120.54c-4.15,0-7.5,3.35-7.5,7.5v22.85c0,4.15,3.35,7.5,7.5,7.5h14.38c1.36,17.27,15.82,30.91,33.43,30.91s32.07-13.64,33.43-30.91h103.44c1.36,17.27,15.82,30.91,33.43,30.91s32.07-13.64,33.43-30.91h7.71c8.27,0,14.98-6.71,14.98-14.98v-39.17c0-7.5-5.55-13.84-12.97-14.84ZM146.64,171.67c-9.79,0-17.72-7.93-17.72-17.72s7.93-17.72,17.72-17.72,17.72,7.93,17.72,17.72-7.93,17.72-17.72,17.72ZM293.7,81.44h-48.48c-.83,0-1.5-.67-1.5-1.49l-.07-31c0-.83.67-1.5,1.5-1.5h24.25c4.6,0,8.85,2.48,11.12,6.44l14.49,25.31c.57,1-.15,2.24-1.3,2.24ZM316.95,171.67c-9.79,0-17.72-7.93-17.72-17.72s7.93-17.72,17.72-17.72,17.72,7.93,17.72,17.72-7.93,17.72-17.72,17.72Z"
    /><path
      class="delivery-cls-2"
      d="M85.62,0h115.69c4.13,0,7.49,3.35,7.49,7.49v93.22c0,4.13-3.35,7.49-7.49,7.49h-115.69c-4.13,0-7.49-3.35-7.49-7.49V7.49c0-4.13,3.35-7.49,7.49-7.49Z"
    /><path
      class="delivery-cls-1"
      d="M8.3,73.46h49.13c4.58,0,8.3,3.72,8.3,8.3h0c0,4.58-3.72,8.3-8.3,8.3H8.3c-4.58,0-8.3-3.72-8.3-8.3h0c0-4.58,3.72-8.3,8.3-8.3Z"
    /><path
      class="delivery-cls-1"
      d="M22.13,45.8h35.3c4.58,0,8.3,3.72,8.3,8.3h0c0,4.58-3.72,8.3-8.3,8.3H22.13c-4.58,0-8.3-3.72-8.3-8.3h0c0-4.58,3.72-8.3,8.3-8.3Z"
    /><path
      class="delivery-cls-1"
      d="M35.96,18.13h21.47c4.58,0,8.3,3.72,8.3,8.3h0c0,4.58-3.72,8.3-8.3,8.3h-21.47c-4.58,0-8.3-3.72-8.3-8.3h0c0-4.58,3.72-8.3,8.3-8.3Z"
    /></g
  ></svg
>
