<script>
  import IconButton from "@smui/icon-button";
  import { format as formatDate } from "date-fns";
  import { ja as localeJa } from "date-fns/locale";
  import { HTTPError } from "ky";
  import { getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import { OfflineException } from "~/libs/backendApi";
  import { CONTEXT_KEY_USER, ConfirmDialogTypes } from "~/libs/constants";
  import deliveryListUtils from "~/libs/deliveryListUtils";
  import requestsQueueForRetry from "~/libs/requestsQueueForRetry";
  import { toast } from "~/libs/toast";
  import { formatTrackingNumber } from "~/libs/trackingNumberUtils";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {Function} 配達リストの再読み込み関数 */
  export let reloadShippingList;

  /** @type {boolean} 送信失敗リストの有無 */
  export let hasSyncFailurePackages;

  /** @type {ConfirmDialog} 確認ダイアログコンポーネントのインスタンス */
  let dialog;

  /** @type {Array<import("~/libs/commonTypes").SyncFailureDeliveryPackage>} */
  let syncFailureList = userContext.syncFailureList;

  /**
   * ダイアログを開く
   */
  export function openDialog() {
    dialog.openDialog();
  }

  /**
   * リクエストの再送を行い、配達リスト画面に反映する
   * @param {string} id
   */
  async function resendAndUpdateList(id) {
    try {
      // idを指定して送信待ちリクエストを再送
      const result = await requestsQueueForRetry.resendById(id);

      // 該当の荷物を更新失敗リストから配達リストに戻す
      await updateLocalStorage(
        result.trackingNumber,
        result.updateResponse,
        result.updateRequestJson,
      );

      // DBから該当のリクエストを削除
      await requestsQueueForRetry.deleteRequestsById(id);

      // 配達リストの再読み込み
      reloadShippingList(userContext.deliveryList);

      if (syncFailureList.length === 0) {
        hasSyncFailurePackages = false;
        dialog.closeDialog();
      }
    } catch (error) {
      if (error instanceof OfflineException) {
        toast.error($_("errors.offline"));
      } else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status === 401
      ) {
        toast.error($_("errors.unauthorized"));
      }
      // 権限エラー等の場合
      else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status === 403
      ) {
        toast.error($_("errors.forbidden"));
      }
      // その他サーバーエラー応答を受信した場合
      else {
        console.error(error);
        toast.error($_("errors.defaultMessage"));
      }
    }
  }

  /**
   * ローカルストレージの更新
   * @param {string} trackingNumber
   * @param {import("~/libs/backendApi").UpdateShipmentStatusResponse} updateResponse
   * @param {object} updateRequestJson
   */
  function updateLocalStorage(
    trackingNumber,
    updateResponse,
    updateRequestJson,
  ) {
    const deliveryList = userContext.deliveryList ?? [];
    const tmpSyncFailureList = userContext.syncFailureList ?? [];
    const index = tmpSyncFailureList.findIndex(
      (e) => e.trackingNumber === trackingNumber,
    );

    if (updateResponse.updateFailed) {
      // 更新結果がupdateFailedだった場合、メッセージを表示
      toast.error(
        $_("errors.isCannotBeDeliveredPackage_2", {
          values: {
            trackingNumber: formatTrackingNumber(trackingNumber),
          },
        }),
      );
    } else {
      // 更新結果が成功だった場合
      // 更新失敗リストから配達リストにコピー
      deliveryList.push(tmpSyncFailureList[index]);

      // 更新情報を反映
      deliveryListUtils.updateDeliveryList(
        deliveryList,
        updateResponse.success[0],
        updateRequestJson,
      );

      toast.info($_("message.resendSuccessful"));
    }
    // 更新失敗リストから削除
    tmpSyncFailureList.splice(index, 1);

    userContext.deliveryList = deliveryList;
    userContext.syncFailureList = tmpSyncFailureList;
    syncFailureList = tmpSyncFailureList;
    userContext.store();
  }

  /**
   * 送信失敗リストから削除
   * @param {string} trackingNumber
   */
  function deleteFromSyncFailureList(trackingNumber) {
    const tmpSyncFailureList = userContext.syncFailureList ?? [];
    const index = tmpSyncFailureList.findIndex(
      (e) => e.trackingNumber === trackingNumber,
    );
    tmpSyncFailureList.splice(index, 1);
    userContext.syncFailureList = tmpSyncFailureList;
    syncFailureList = tmpSyncFailureList;
    userContext.store();

    toast.info($_("message.deleteFromSyncFailureListSuccessful"));

    if (syncFailureList.length === 0) {
      hasSyncFailurePackages = false;
      dialog.closeDialog();
    }
  }
</script>

<div class="syncFailureListDialog wideWidthMdcDialog">
  <ConfirmDialog
    bind:this={dialog}
    type={ConfirmDialogTypes.CLOSE}
    mandatory={false}
  >
    <svelte:fragment slot="title">送信待ち荷物リスト</svelte:fragment>
    <svelte:fragment slot="content">
      <div class="syncFailureListArea">
        {#each syncFailureList as item, index}
          {#if index > 0}
            <div class="divider" />
          {/if}
          <section>
            <div class="mainContents">
              <div class="packageInfoArea">
                <p class="line1">
                  {formatDate(new Date(item.failedTime), "M/d(E) HH:mm", {
                    locale: localeJa,
                  })}
                  <span class="">
                    {#if Number.isInteger(item.reasonForUndeliverable)}
                      {$_(
                        `pages.List.extraEventTypeLabel.${item.reasonForUndeliverable}`,
                      )}
                    {:else}
                      配達完了
                    {/if}
                  </span>
                </p>
                <p class="line2">{formatTrackingNumber(item.trackingNumber)}</p>
              </div>
              <div class="buttonArea">
                {#if item.retryId}
                  <IconButton
                    class="material-icons"
                    size="button"
                    style="font-size: 25px;"
                    on:click={() => {
                      resendAndUpdateList(item.retryId);
                    }}>sync</IconButton
                  >
                {:else}
                  <IconButton
                    class="material-icons"
                    size="button"
                    style="font-size: 25px;"
                    on:click={() => {
                      deleteFromSyncFailureList(item.trackingNumber);
                    }}>delete_sweep</IconButton
                  >
                {/if}
              </div>
            </div>
            {#if !item.retryId}
              <div class="subContents">
                <p class="caution">{$_("errors.cantResend")}</p>
              </div>
            {/if}
          </section>
        {/each}
      </div>
    </svelte:fragment>
  </ConfirmDialog>
</div>

<style lang="scss">
  .syncFailureListDialog {
    :global(.mdc-dialog__title) {
      text-align: center;
    }

    .syncFailureListArea {
      max-height: 70vh;
      overflow-y: auto;
      border: solid 1px #e7e7e7;
      border-radius: 10px;

      .divider {
        height: 1px;
        background-color: #e7e7e7;
      }

      section {
        padding: 10px;
        display: column;

        .mainContents {
          display: flex;

          .packageInfoArea {
            font-size: 15px;

            .line1 {
              line-height: 1;
            }

            .line2 {
              margin: 5px 0 0 5px;
              font-size: large;
              line-height: 1.2;
            }
          }

          .buttonArea {
            margin-left: auto;
            display: flex;
            align-items: center;
          }
        }

        .caution {
          font-size: 14px;
          margin-top: 5px;
          background-color: #ffe7e7;
          color: #c80000;
          border-radius: 4px;
          padding: 3px 4px;
          line-height: 1.4;
        }
      }
    }
  }
</style>
