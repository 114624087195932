import { kanji2number } from "@geolonia/japanese-numeral";
import { config, normalize } from "@scc-kk/normalize-japanese-addresses";
import ky from "ky";

/**
 * @typedef {object} PostcodeAddress
 * @property {string} addr 住所
 * @property {number} [lat] 緯度
 * @property {number} [lng] 経度
 * @property {number} [level] 緯度・経度の精度レベル (2: 市区町村、3: 町丁目、8: 住居表示住所の街区符号・住居番号)
 */

// 住所正規化ライブラリの初期化
if (import.meta.env.VITE_JAPANESE_ADDRESSES) {
  config.japaneseAddressesApi = import.meta.env.VITE_JAPANESE_ADDRESSES;
}

/**
 * 郵便番号と住所の変換用キャッシュ
 * @type {Map<string, Array<PostcodeAddress>>}
 */
const postcodeAddressesCache = new Map();

const addressUtils = {
  /**
   * 都道府県を除いた住所を返します。
   * @param {string} address 住所
   * @returns {string} 都道府県を除いた住所
   */
  trimPrefecture(address) {
    return address.replace(/^.{2,3}[都道府県]/, "");
  },

  /**
   * 2つの住所を半角スペース付きで結合して返す。
   * @param {string} address1
   * @param {string} address2
   * @returns {string}
   */
  joinWithSpace(address1, address2) {
    return address2 ? address1 + " " + address2 : address1;
  },

  /**
   * Geoloniaの住所正規化ライブラリで住所を正規化する。
   * @param {string} address 住所
   * @returns {Promise<import("@scc-kk/normalize-japanese-addresses").NormalizeResult>} 正規化済の住所
   */
  async normalizeAddress(address) {
    return await normalize(address);
  },

  /**
   * Googleマップへのリンク用に住所を正規化する。
   * @param {string} address 住所
   * @returns {Promise<string>} 正規化済の住所
   */
  async normalizeAddressForGoogleMap(address) {
    let normalizedAddress = address;
    try {
      const nr = await normalize(address);
      if (nr.level == 8) {
        let other;
        if (nr.other) {
          other = nr.other.replace(/^(-\d+)(?:-\d+)*\s*号?室?\s*/, "$1 ");
          const firstSpaceIndex = other.indexOf(" ");
          if (firstSpaceIndex != -1) {
            other =
              other.substring(0, firstSpaceIndex + 1) +
              other
                .substring(firstSpaceIndex + 1)
                .replace(/\s*(?:株式|有限|合同|合資|合名)会社.+$/, "")
                .replace(/\s*\d+号?室?$/, "")
                .replace(/\s*\d+[階Ff]$/, "");
          }
          other = other.trim();
        }
        normalizedAddress =
          (nr.pref ?? "") +
          (nr.city ?? "") +
          (nr.town ?? "") +
          (nr.addr ?? "") +
          (other ?? "");
      }
    } catch (error) {
      console.warn(error);
    }
    return normalizedAddress;
  },

  /**
   * 郵便番号に対応する住所を取得する。
   * @param {string} postcode
   * @returns {Promise<Array<PostcodeAddress>>}
   */
  async getAddressesByPostcode(postcode) {
    const postcodeAddresses = postcodeAddressesCache.get(postcode);
    if (!postcodeAddresses) {
      try {
        /** @type {{[key: string]: Array<PostcodeAddress>}} */
        const responseData = await ky
          .get(
            `${import.meta.env.VITE_POSTCODE_ADDRESSES}/${postcode.substring(0, 3)}.json`,
          )
          .json();
        for (const key in responseData) {
          const value = responseData[key];
          postcodeAddressesCache.set(key, value);
        }
        return responseData[postcode];
      } catch (error) {
        console.warn(error);
        // fall through
      }
    }
    return postcodeAddresses;
  },

  /**
   * 住所の丁目・番地・号の部分を`1-2-3`形式で抽出する。
   * 丁目・番地・号のいずれか2つ以上が存在しない場合は正規の住所ではないと判定し、undefinedを返す。
   * @param {string} address
   * @returns {string}
   */
  extractChomeBanchiGou(address) {
    return /(\d+(?:-\d+){1,2})/.exec(
      address
        .normalize("NFKC")
        .replace(/[−‐⁃‑‒–—﹘―⎯⏤ーｰ─━]/g, "-")
        .replace(/(\d+|[〇一二三四五六七八九十])\s*丁目\s*/, (match, chome) => {
          if (chome) {
            return `${kanji2number(chome)}-`;
          } else {
            return match;
          }
        })
        .replace(
          /\s*(\d+|[〇一二三四五六七八九十百千]+)\s*番地?(?:\s*(\d+|[〇一二三四五六七八九十百千]+)\s*号?)?/,
          (match, banchi, gou) => {
            if (banchi && gou) {
              return `${kanji2number(banchi)}-${kanji2number(gou)}`;
            } else if (banchi) {
              return `${kanji2number(banchi)}`;
            } else {
              return match;
            }
          },
        ),
    )?.[1];
  },
};

export default Object.freeze(addressUtils);
