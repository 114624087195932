import JSBI from "jsbi";

/**
 * @typedef {{
 *   desiredDeliveryMethod: 0 | 1 | 2 | 3 | 4 | 5,
 *   availableDeliveryMethod: Array<0 | 1 | 2 | 3 | 5 | number>,
 *   receiverSelectableDeliveryMethod: Array<0 | 1 | 2 | 3 | 5 | number>,
 *   receiptStampRequred: boolean,
 *   cashOnDeliveryRequred: boolean,
 * }} ParsingResult
 */

const BASE45_CHARS = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ $%*+-./:";
const BI1 = JSBI.BigInt(1);
const BI4 = JSBI.BigInt(4);
const BI15 = JSBI.BigInt(15);

const SELECTABLE_DELIVERY_METHODS_PATTERN_MAP = new Map([
  [
    0,
    {
      availableDeliveryMethod: [0, 1, 2, 3, 5],
      receiverSelectableDeliveryMethod: [0, 1, 2, 3, 5],
    },
  ],
  [
    1,
    {
      availableDeliveryMethod: [5],
      receiverSelectableDeliveryMethod: [0, 1, 2, 3, 5],
    },
  ],
  [
    2,
    {
      availableDeliveryMethod: [1, 5],
      receiverSelectableDeliveryMethod: [0, 1, 2, 3, 5],
    },
  ],
  [3, { availableDeliveryMethod: [5], receiverSelectableDeliveryMethod: [5] }],
  [
    4,
    {
      availableDeliveryMethod: [1, 5],
      receiverSelectableDeliveryMethod: [1, 5],
    },
  ],
]);

/**
 * QRコードに埋め込まれた制御情報(Base45文字列)を解析し、ParsingResultオブジェクトに変換します。
 * @param {string} embeddedString QRコードに埋め込まれたBase45文字列
 * @returns {ParsingResult} 制御情報を含んだオブジェクト
 * @throws {TypeError} QRコードの形式が不正な場合
 */
export function parseQRCodeEmbeddedDeliveryInfo(embeddedString) {
  try {
    if (embeddedString.length == 14 && embeddedString.startsWith("SCC")) {
      const i = JSBI.BigInt(
        BASE45_CHARS.indexOf(embeddedString.substring(12, 13)),
      );
      const j = JSBI.BigInt(
        BASE45_CHARS.indexOf(embeddedString.substring(13, 14)),
      );
      const receiptStampRequred = JSBI.equal(JSBI.signedRightShift(i, BI4), BI1)
        ? true
        : false;
      const availableDeliveryMethod =
        SELECTABLE_DELIVERY_METHODS_PATTERN_MAP.get(
          JSBI.toNumber(JSBI.bitwiseAnd(i, BI15)),
        ).availableDeliveryMethod;
      const receiverSelectableDeliveryMethod =
        SELECTABLE_DELIVERY_METHODS_PATTERN_MAP.get(
          JSBI.toNumber(JSBI.bitwiseAnd(i, BI15)),
        ).receiverSelectableDeliveryMethod;
      const cashOnDeliveryRequred = JSBI.equal(
        JSBI.signedRightShift(j, BI4),
        BI1,
      );
      const desiredDeliveryMethod = JSBI.equal(JSBI.bitwiseAnd(j, BI15), BI15)
        ? null
        : JSBI.toNumber(JSBI.bitwiseAnd(j, BI15));
      return {
        desiredDeliveryMethod,
        availableDeliveryMethod,
        receiverSelectableDeliveryMethod,
        cashOnDeliveryRequred,
        receiptStampRequred,
      };
    } else {
      return null;
    }
  } catch (error) {
    console.log(
      "[base45DecodeUtils.js] parseQRCodeEmbeddedDeliveryInfo:",
      error,
    );
  }
  throw new TypeError("制御情報の形式が不正です: " + embeddedString);
}
