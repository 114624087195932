<script>
  import { _ } from "svelte-i18n";

  import {
    AVAILABLE_DROP_OFF_LOCATIONS,
    DropOffLocation,
  } from "~/libs/constants";
  import { deliveryTarget } from "~/libs/stores";
  import LockerInformation from "~/pages/Update/LockerInformation.svelte";

  /** @type {number} 宅配ドライバーが選択した受け渡し方法 */
  export let actualPackageDropPlace;
  /** @type {string} 宅配ボックスのボックス番号（コンポーネント間連携用） */
  export let lockerNumber;
  /** @type {string} 宅配ボックスの暗証番号（コンポーネント間連携用） */
  export let lockerPin;

  /** @type {number} 荷受人が希望した受け渡し方法 */
  const packageDropPlace = $deliveryTarget.packageDropPlace;

  /** @type {boolean} ヘルプダイアログの開閉フラグ */
  let helpPageOpen = false;

  const selectableList = (() => {
    let selectableList = [];
    for (const availableDropOffLocation of AVAILABLE_DROP_OFF_LOCATIONS) {
      let selectable = false;
      let receiverSelectable = false;

      if (
        $deliveryTarget.customer?.availableDeliveryMethod?.includes(
          availableDropOffLocation,
        )
      ) {
        selectable = true;
      }

      if (
        $deliveryTarget.customer?.receiverSelectableDeliveryMethod?.includes(
          availableDropOffLocation,
        )
      ) {
        receiverSelectable = true;
      }

      selectableList.push({
        deliveryMethod: availableDropOffLocation,
        selectable: selectable,
        receiverSelectable: receiverSelectable,
      });
    }
    return selectableList;
  })();

  (() => {
    actualPackageDropPlace = packageDropPlace;
  })();

  function toggleHelpPage() {
    helpPageOpen = !helpPageOpen;
  }
</script>

<!-- 配達場所 -->
<div class="changeArea">
  <div class="changeTitle">
    <p>
      受け渡し方法の選択
      <span class="required">必須</span>
    </p>
    <button class="helpBtn" on:click={toggleHelpPage}>
      <div style="width: 30px;">
        <span class="material-icons md-dark md-24">info_outline</span>
      </div>
    </button>
  </div>

  <div class="placeButtons">
    {#each selectableList as selectableMethod}
      <button
        class="placeButton"
        class:desired={selectableMethod.deliveryMethod === packageDropPlace}
        class:exCurrent={selectableMethod.deliveryMethod ===
          actualPackageDropPlace}
        disabled={!selectableMethod.receiverSelectable}
        on:click={() => {
          actualPackageDropPlace =
            actualPackageDropPlace === selectableMethod.deliveryMethod
              ? null
              : selectableMethod.deliveryMethod;
        }}
        >{$_(
          `classes.packageDropPlace.${selectableMethod.deliveryMethod}`,
        )}{#if !selectableMethod.selectable || !selectableMethod.receiverSelectable}<br
          /><span class="subLabel"
            >({selectableMethod.receiverSelectable
              ? "要望時のみ可"
              : "選択不可"})</span
          >{/if}</button
      >
    {/each}
  </div>
  {#if Number.isInteger(packageDropPlace) && Number.isInteger(actualPackageDropPlace) && actualPackageDropPlace !== packageDropPlace}
    <div class="placeWarning">
      <span class="material-icons">warning</span>
      <span>ご希望と異なる受け渡し方法が選択されています</span>
    </div>
  {/if}

  <div
    class="lockerAreaWrapper"
    style:display={actualPackageDropPlace === DropOffLocation.LOCKER
      ? "block"
      : "none"}
  >
    <LockerInformation bind:lockerNumber bind:lockerPin />
  </div>

  <!-- ヘルプダイアログ -->
  <div class="dropPlace" style="display: {helpPageOpen ? 'block' : 'none'}">
    <div class="dropPlaceContentWrap">
      <button class="dropPlaceCloseLabel" on:click={toggleHelpPage}>×</button>
      <div class="dropPlaceContent">
        <div class="dropPlaceArea">
          <div class="dropPlaceTitle">
            <div class="icons" style="width: 30px; margin-right: 5px">
              <span class="material-icons md-dark md-24">help_outline</span>
            </div>
            <h3>受け渡し方法について</h3>
          </div>
          <div class="dropPlaceNotion">
            <span
              >EC事業者との契約上許可されていない受け渡し方法は選択できません。
              <p style="height: 8px;"></p>
              「要望時のみ可」と表示されている受け渡し方法は、荷受人との電話やインターホンでの会話によって指示を受けた場合のみ選択してください。
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="dropPlaceBackground" />
  </div>
</div>

<style lang="scss">
  /* ------------------
    受け渡し方法の選択
  --------------------- */
  .changeArea {
    display: block;
    background-color: #fff;
    border-radius: 10px;

    .changeTitle {
      position: relative;
      margin: 0 0 10px 30px;
      display: flex;
      font-weight: bold;

      .required {
        display: inline-block;
        padding: 2px 4px;
        margin-left: 4px;
        color: #c80000;
        background-color: #c8000010;
      }
    }

    .helpBtn {
      position: absolute;
      top: -7px;
      right: 10px;
      width: 30px;
      height: 30px;
      background-color: #fff;
      border: none;
      border-radius: 50%;
      padding: 0;
      margin: 0;
    }
  }

  .placeButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 5px;

    .placeButton {
      width: 110px;
      height: 45px;
      padding: 0;
      background-color: #fff;
      border: 1px solid #018786;
      border-radius: 10px;
      box-shadow: 0 2px #ccc;
      font-size: 13px;
      color: #333;
      line-height: 1.2;

      &:disabled {
        background-color: #ccc;
        color: #646464;
      }

      &.desired {
        color: #2400d6;
      }

      &.exCurrent {
        background-color: #2400d610;
      }

      .subLabel {
        font-size: 12px;
      }
    }
  }

  .placeWarning {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    color: var(--mdc-theme-error);

    span {
      font-size: 13px;
    }
  }

  /* ------------------
    モーダルウィンドウ
   -------------------- */

  /* 写真撮影のポイント */
  .dropPlace {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;

    .dropPlaceContentWrap {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 350px;
      background-color: #fefefe;
      z-index: 2;
      border-radius: 5px;
    }
    .dropPlaceCloseLabel {
      background-color: #777;
      color: #fff;
      border: 2px solid #fff;
      border-radius: 20px;
      width: 36px;
      height: 36px;
      line-height: 1.5;
      text-align: center;
      display: table-cell;
      position: fixed;
      top: -15px;
      right: -2%;
      z-index: 99999;
      font-size: 1.4em;
      cursor: pointer;
      padding: 0;
      margin: 0;
    }
    .dropPlaceContent {
      max-height: 60vh;
      overflow-y: auto;
      padding: 30px;
    }
    .dropPlaceArea {
      position: relative;
      margin: 0 auto;
      padding: 15px 0;
      background-color: #fff;
      border-radius: 10px;
    }
    .dropPlaceTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }
    .dropPlaceTitle h3 {
      font-size: 18px;
    }
    .dropPlaceNotion {
      font-size: 16px;
      line-height: 23px;
      text-align: left;
      padding-left: 10px;
      list-style-type: circle;
    }
    .dropPlaceBackground {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.45);
      z-index: 1;
    }
  }
</style>
