<script>
  import Button, { Label } from "@smui/button";
  import IconButton from "@smui/icon-button";
  import Textfield from "@smui/textfield";
  import { HTTPError } from "ky";
  import { getContext, onDestroy } from "svelte";
  import { _ } from "svelte-i18n";
  import UAParser from "ua-parser-js";

  import logo from "~/assets/images/logo.svg";
  import HelpAfterLogin from "~/components/help/HelpAfterLogin.svelte";
  import HelpBase from "~/components/help/HelpBase.svelte";
  import HelpOfflineMode from "~/components/help/HelpOfflineMode.svelte";
  import backendApi, {
    ErrorResponseException,
    OfflineException,
  } from "~/libs/backendApi";
  import { HandledError } from "~/libs/commonTypes";
  import { OfflineModeTypes } from "~/libs/constants";
  import {
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    CORE_DELIVERY_ROLE,
    DISABLE_ROLES,
    DRIVER_ROLE,
  } from "~/libs/constants";
  import iosNativeApp from "~/libs/iosNativeApp";
  import loadingProgress from "~/libs/loadingProgress";
  import logger from "~/libs/logger";
  import offlineBackendApi from "~/libs/offlineBackendApi";
  import pageRouter from "~/libs/pageRouter";
  import { displayOfflineModeHelp } from "~/libs/stores";
  import { toast } from "~/libs/toast";

  /** ユーザーID @type {string} */
  export let id;
  /** パスワード @type {string} */
  export let pw;
  /** 初期パスワード変更ページを表示するか否か @type {boolean} */
  export let showsInitialPasswordChangePage;
  /** パスワードリセットページを表示するか否か @type {boolean} */
  export let showsPasswordResetPage;

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** パスワードのtype属性値 */
  let pwType = "password";

  /** エラーメッセージ @type {string} */
  let errorMessage;

  /** ログイン後の初回表示ヘルプを表示するか否か */
  let showsAfterLoginHelp = false;

  /** オフラインモードヘルプを表示するか否か */
  let showsOfflineModeHelp = false;

  /** @type {{username: string, password: string}} キュー保存用のログインAPIリクエスト */
  let loginApiRequestForOfflineMode;

  /** displayOfflineModeHelpのUnsubscriver @type {import("svelte/store").Unsubscriber} */
  const displayOfflineModeHelpUnsubscriber = displayOfflineModeHelp.subscribe(
    (display) => {
      if (display) {
        showsOfflineModeHelp = true;
      }
    },
  );

  onDestroy(() => {
    displayOfflineModeHelpUnsubscriber?.();
  });

  /** 「ログイン」ボタンのdisabled属性を有効にするか否か */
  $: loginButtonDisabled = !(id && pw);

  const login = loadingProgress.wrapAsync(async () => {
    try {
      const responseBody = await backendApi.login({
        username: id,
        password: pw,
      });
      if (DISABLE_ROLES.includes(responseBody.roles[0])) {
        throw new HandledError($_("errors.disableRoles"));
      } else {
        // iOSネイティブアプリの場合は認証情報の保存をサジェストさせる
        iosNativeApp.saveCredentials(id, pw);

        const currentTime = Date.now();
        userContext.loginUser = {
          username: responseBody.username,
          roles: responseBody.roles,
          accessToken: responseBody.accessToken,
          refreshToken: responseBody.refreshToken,
          expiresIn: responseBody.expiresIn,
          refreshExpires:
            responseBody.refreshExpiresIn > 0
              ? currentTime + responseBody.refreshExpiresIn * 1000
              : undefined,
          loginTime: currentTime,
          displayName: responseBody.displayName,
          companyId: responseBody.companyId,
          companyName: responseBody.companyName,
          emailAddress: responseBody.emailAddress,
          switchableRoles: responseBody.switchableRoles,
          switchableCompanies: responseBody.switchableCompanies,
        };
        if (!appContext.firstLoginCompleted) {
          appContext.firstLoginCompleted = showsAfterLoginHelp = true;
        } else {
          subscribeWebPush();
          pageRouter.moveToMain();
        }
        appContext.store();
        userContext.store();
      }
      errorMessage = null;
    } catch (error) {
      if (
        error instanceof ErrorResponseException &&
        error.errorResponse.title === "Require password reset."
      ) {
        // 初期パスワード変更が必要な場合、初期パスワード変更ページを表示
        showsInitialPasswordChangePage = true;
      } else if (
        error instanceof HandledError ||
        (error instanceof HTTPError &&
          error.response &&
          (error.response.status == 401 || error.response.status == 400))
      ) {
        // 認証エラー応答等を受信した場合、エラーメッセージを表示
        showErrorMessage(error);
      } else {
        try {
          // オフライン状態やサーバーエラー応答等が発生した場合
          // IDを基にロールを判定
          const role = judgeUserRole(id);

          // ロールに応じて閉塞フラグを確認し、オフラインモード切替えが可能かを判定
          if (
            (role == CORE_DELIVERY_ROLE &&
              import.meta.env.VITE_DISABLED_OFFLINE_MODE_PICKUP_AND_SORT !==
                "true") ||
            (role == DRIVER_ROLE &&
              import.meta.env.VITE_DISABLED_OFFLINE_MODE_DELIVERED !== "true")
          ) {
            // オフラインモード切替えが可能な場合
            // ログインAPIのリクエストを保持して、オフラインモード切替えヘルプを表示
            loginApiRequestForOfflineMode = {
              username: id,
              password: pw,
            };
            if (error instanceof OfflineException) {
              toast.recommendOfflineMode($_("errors.offline"));
            } else {
              toast.recommendOfflineMode($_("errors.defaultMessage"));
            }
          } else {
            // オフラインモード切替えが不可の場合
            // 認証失敗のエラーメッセージを表示
            showErrorMessage(error);
          }
        } catch (error) {
          showErrorMessage(error);
        }
      }
    }
  });

  // TODO: 権限要求・サブスクライブをリトライできる仕組みを構築する (#2188)
  async function subscribeWebPush() {
    if (window.webkit?.messageHandlers) {
      // ネイティブアプリの場合はデバイストークンを取得してBEに登録
      const deviceTokenJson = await iosNativeApp.getDeviceToken();
      if (deviceTokenJson?.bundleId && deviceTokenJson?.deviceToken) {
        try {
          userContext.iosNativeAppPushInfo = {
            bundleId: deviceTokenJson.bundleId,
            deviceToken: deviceTokenJson.deviceToken,
          };
          userContext.store();
          await execRegisterApnsDeviceTokenApi(deviceTokenJson);
        } catch (error) {
          toast.error($_("errors.webPushUnknownErrorOccurred") + error.message);
          console.error(error);
          return;
        }
      }
    } else {
      // PWAの場合はサブスクライブを実行
      if (!("Notification" in window) || !("serviceWorker" in navigator)) {
        toast.error($_("errors.webPushIsNotSupported"));
        return;
      }

      let publicKey;
      try {
        publicKey = await backendApi.getWebPushPublicKey();
        console.log(publicKey);
        console.log(urlB64ToUint8Array(publicKey));

        if (window.Notification.permission === "default") {
          const result = await window.Notification.requestPermission();
          if (result === "default") {
            toast.error($_("errors.webPushSubscriptionIsCancelled"));
            return;
          }
        }
        if (window.Notification.permission === "denied") {
          toast.error(
            $_("errors.webPushPermissionIsDenied", {
              values: {
                browserName:
                  UAParser(navigator.userAgent)
                    ?.browser?.name?.replace(/^Mobile /, "")
                    ?.replace("WebKit", "アプリ") ?? "ブラウザ",
              },
            }),
          );
        }
      } catch (error) {
        toast.error($_("errors.webPushUnknownErrorOccurred") + error.message);
        console.error(error);
        return;
      }

      try {
        const swRegistration = await navigator.serviceWorker.ready;
        if (!("pushManager" in swRegistration)) {
          toast.error($_("errors.webPushIsNotSupported"));
          return;
        }

        const pushSubscription = await swRegistration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlB64ToUint8Array(publicKey),
        });

        const subscriptionJSON = pushSubscription.toJSON();
        if (
          subscriptionJSON.endpoint == null ||
          subscriptionJSON.keys == null
        ) {
          toast.error($_("errors.webPushIsNotSupported"));
          return;
        }

        await execSubscriptionApi(subscriptionJSON);
      } catch (error) {
        toast.error($_("errors.webPushUnknownErrorOccurred") + error.message);
        console.error(error);
        return;
      }
    }
  }

  /**
   * APNsデバイストークン登録APIを実行する
   * @param {object} deviceTokenJson
   */
  async function execRegisterApnsDeviceTokenApi(deviceTokenJson) {
    const body = {
      bundleId: deviceTokenJson.bundleId,
      deviceToken: deviceTokenJson.deviceToken,
    };
    await backendApi.registerApnsDeviceToken(body);
  }

  async function execSubscriptionApi(subscriptionJSON) {
    let body = {
      endpoint: subscriptionJSON.endpoint,
      expiration_time: subscriptionJSON.expirationTime ?? null,
      keys: {
        p256dh: subscriptionJSON.keys.p256dh,
        auth: subscriptionJSON.keys.auth,
      },
    };
    await backendApi.registerWebPushSubscription(body);
  }

  /**
   * トークンを変換するときに使うロジック
   * @param {string} base64String
   * @returns {Uint8Array}
   */
  function urlB64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  /**
   * @param {KeyboardEvent} event
   */
  function onEnterKeyDownHandler(event) {
    if (event.key === "Enter" && !loginButtonDisabled) {
      login();
    }
  }

  /**
   * オフラインモードへの切替えを行う。
   */
  function switchOfflineMode() {
    try {
      const role = judgeUserRole(loginApiRequestForOfflineMode.username);
      appContext.offlineMode = true;
      offlineBackendApi.login(loginApiRequestForOfflineMode);
      if (role == CORE_DELIVERY_ROLE) {
        appContext.offlineModeType = OfflineModeTypes.PICKUP_AND_SORT;
        pageRouter.moveToOfflineMode(OfflineModeTypes.PICKUP_AND_SORT);
      } else {
        appContext.offlineModeType = OfflineModeTypes.DELIVERED;
        pageRouter.moveToOfflineMode(OfflineModeTypes.DELIVERED);
      }
      appContext.store();
    } catch (error) {
      showErrorMessage(error);
    }
  }

  /**
   * ユーザーIDからユーザーのロールを判定する。
   * @param {string} inputId
   * @returns {CORE_DELIVERY_ROLE | DRIVER_ROLE} ユーザーのロール
   */
  function judgeUserRole(inputId) {
    if (inputId.match(/^[0]\d{3}\/.+/)) {
      // "0"から始まるIDの場合、基幹配送担当ユーザーと判断
      return CORE_DELIVERY_ROLE;
    } else if (inputId.match(/^[1]\d{3}\/.+/)) {
      // "1"から始まるIDの場合、宅配ドライバーユーザーと判断
      return DRIVER_ROLE;
    } else {
      // それ以外のIDの場合、エラーメッセージ表示
      throw new HandledError($_("errors.loginFailed"));
    }
  }

  /**
   * エラーメッセージをダイアログで表示する。
   * @param {Error} error Errorオブジェクト
   */
  function showErrorMessage(error) {
    if (error instanceof HandledError) {
      errorMessage = error.message;
    } else {
      if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 401
      ) {
        errorMessage = $_("errors.loginFailed");
      } else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 400
      ) {
        errorMessage = $_("errors.loginFailedLimitOrver");
      } else {
        logger.error(
          "[NormalLogin] ログインでエラーが発生しました",
          {
            username: id,
          },
          error,
        );
        errorMessage = $_("errors.loginDefaultMessage");
      }
    }
  }
</script>

<div class="heading">
  <p class="logo">
    <img src={logo} width="100" alt="logo" />
  </p>
</div>

<div class="inputField">
  <Textfield
    type="text"
    label="ユーザーID"
    variant="outlined"
    style="margin-top: 30px;"
    required
    input$id="username"
    input$name="username"
    input$autocomplete="username"
    bind:value={id}
    on:keydown={onEnterKeyDownHandler}
  />
</div>
<div class="inputField passwordField">
  <Textfield
    type={pwType}
    label="パスワード"
    variant="outlined"
    style="margin-top: 15px;"
    required
    input$id="current-password"
    input$name="current-password"
    input$autocomplete="current-password"
    bind:value={pw}
    on:keydown={onEnterKeyDownHandler}
  >
    <IconButton
      slot="trailingIcon"
      class="material-icons md-dark"
      tabindex={-1}
      on:click={() => {
        pwType = pwType == "text" ? "password" : "text";
      }}
    >
      {pwType == "text" ? "visibility_off" : "visibility"}
    </IconButton>
  </Textfield>
</div>

<div class="passwordReset">
  <Button
    color="secondary"
    ripple={false}
    on:click={() => {
      showsPasswordResetPage = true;
    }}
  >
    <Label>パスワードをお忘れの方はこちら</Label>
  </Button>
</div>

{#if errorMessage}
  <p class="errorMessage">
    <!-- ja.jsonに定義されたメッセージしか表示されないためHTMLエスケープ不要 -->
    {@html errorMessage}
  </p>
{/if}

<div class="command">
  <Button
    type="submit"
    variant="unelevated"
    style="width: 150px; height: 50px;"
    touch
    bind:disabled={loginButtonDisabled}
    on:click={login}
    >ログイン
  </Button>
</div>

<!-- ヘルプ表示 -->
{#if showsAfterLoginHelp}
  <HelpBase
    helpContents={HelpAfterLogin}
    clickConfirm={() => {
      showsAfterLoginHelp = false;
      subscribeWebPush();
      pageRouter.moveToMain();
    }}
  />
{/if}
{#if showsOfflineModeHelp}
  <HelpBase
    helpContents={HelpOfflineMode}
    clickConfirm={() => {
      showsOfflineModeHelp = false;
      displayOfflineModeHelp.set(false);
      switchOfflineMode();
    }}
    clickCancel={() => {
      showsOfflineModeHelp = false;
      displayOfflineModeHelp.set(false);
    }}
  />
{/if}

<style lang="scss">
  .passwordReset {
    :global(button) {
      font-size: 14px;
      font-weight: normal;
      text-decoration: underline;
    }
  }
</style>
