<script>
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import { getContext } from "svelte";

  import { CONTEXT_KEY_APP } from "~/libs/constants";

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {string} 宅配ボックスのボックス番号（コンポーネント間連携用） */
  export let lockerNumber = null;
  /** @type {string} 宅配ボックスの暗証番号（コンポーネント間連携用） */
  export let lockerPin = null;

  /** @type {boolean} ボックス番号の入力有無（チェックボックス） */
  let enableInputLockerNumber = true;
  /** @type {boolean} 暗証番号の入力有無（チェックボックス） */
  let enableInputLockerPin = true;

  /** @type {string} 宅配ボックスのボックス番号（コンポーネント内管理用） */
  let internalLockerNumber = "";
  /** @type {string} 宅配ボックスの暗証番号（コンポーネント内管理用） */
  let internalLockerPin = getRamdomPin();

  // テキストボックスの入力値をコンポーネント間連携用の変数に同期
  $: lockerNumber = enableInputLockerNumber ? internalLockerNumber : null;
  $: lockerPin = enableInputLockerPin ? internalLockerPin : null;

  function getRamdomPin() {
    return String(Math.floor(Math.random() * 9999)).padStart(4, "0");
  }

  /**
   * ボックス番号選択ボタンのクリックイベント
   * @param {Event} event
   */
  function onClickLockerNumberButton(event) {
    const button = /** @type {HTMLButtonElement} */ (event.target);
    if (internalLockerNumber == button.textContent) {
      internalLockerNumber = "";
    } else {
      internalLockerNumber = button.textContent;
    }
  }
</script>

<div class="lockerArea">
  <!-- ボックス番号 -->
  <div class="lockerNumberArea">
    <p>
      <FormField>
        <Checkbox bind:checked={enableInputLockerNumber} />
        <span slot="label">ボックス番号を指定</span>
      </FormField>
    </p>
    {#if enableInputLockerNumber}
      <div class="lockerNumberButtons">
        {#each [...Array(50).keys()] as i}
          <button
            class:current={internalLockerNumber === String(i + 1)}
            on:click={onClickLockerNumberButton}>{i + 1}</button
          >
        {/each}
      </div>
      <input
        type="text"
        class="lockerNumber"
        maxlength="4"
        placeholder="その他番号"
        bind:value={internalLockerNumber}
      />
    {/if}
  </div>

  <hr />

  <!-- 暗証番号 -->
  <div class="lockerPinArea">
    <p>
      <FormField>
        <Checkbox bind:checked={enableInputLockerPin} />
        <span slot="label">暗証番号を指定</span>
      </FormField>
    </p>
    {#if enableInputLockerPin}
      <input
        type="text"
        class="lockerPin"
        maxlength="6"
        bind:value={internalLockerPin}
      />
    {/if}
  </div>

  <!-- オフラインモード用注記 -->
  {#if appContext.offlineMode && (enableInputLockerNumber || enableInputLockerPin)}
    <p class="notion">
      ※現在オフラインモードのため、宅配ボックス情報を記入した紙を忘れずに郵便受けに投函してください。
    </p>
  {/if}
</div>

<style lang="scss">
  .lockerArea {
    display: flex;
    flex-direction: column;
    margin: 15px 10px 0;
    padding: 0 15px 10px;
    border: solid 0.5px #b2b2b2;

    input[type="text"] {
      width: 60%;
    }

    hr {
      margin: 15px 0 0;
      border-top: solid 0.5px #b2b2b2;
    }
  }

  .lockerNumberArea {
    // ボックス番号選択ボタン
    .lockerNumberButtons {
      display: flex;
      align-items: center;
      gap: 8px;
      overflow-x: scroll;

      button {
        min-width: 30px;
        min-height: 30px;
        padding: 0;
        border: none;
        border-radius: 3px;
        background-color: #e9e9e9;
        font-size: 15px;
        font-weight: 500;
        color: #333;

        &.current {
          background-color: #018786;
          color: #fff;
        }
      }
    }

    input.lockerNumber {
      margin-top: 10px;
      text-align: center;
      font-size: 16px;
    }
  }

  .lockerPinArea {
    input.lockerPin {
      text-align: center;
      font-size: 16px;
    }
  }

  .notion {
    margin-top: 10px;
    font-size: 13px;
    line-height: 15px;
    color: #ff0000;
  }
</style>
