<svg
  id="notification"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 476.53 475.9"
  class="svg-icons-notification"
  ><defs
    ><style>
      .notification-cls-1 {
        stroke: #1f9ddb;
      }
      .notification-cls-1,
      .notification-cls-2 {
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 26.71px;
      }
      .notification-cls-3 {
        fill: #1b3666;
        stroke-width: 0px;
      }
      .notification-cls-2 {
        stroke: #1b3666;
      }
    </style></defs
  ><g id="_瀧鵫_1"
    ><ellipse
      class="notification-cls-2"
      cx="273.77"
      cy="201.03"
      rx="48.45"
      ry="149.74"
      transform="translate(-59.94 137.79) rotate(-25.56)"
    /><line
      class="notification-cls-1"
      x1="364.07"
      y1="151.81"
      x2="426.63"
      y2="121.62"
    /><line
      class="notification-cls-1"
      x1="307.2"
      y1="66.96"
      x2="351.78"
      y2="13.35"
    /><line
      class="notification-cls-1"
      x1="393.84"
      y1="249.74"
      x2="463.18"
      y2="248.37"
    /><path
      class="notification-cls-3"
      d="M265.04,336.07c-33.76-35.07-58.68-85.27-65.91-99.31-21.38-41.48-32.59-95.02-35.37-109.65-.27-1.44-2.04-1.79-2.84-.57-23.44,35.29-65.3,88.22-122.2,119.73l-13.36,6.39c-23.07,11.04-32.03,40.36-20.01,65.5l13.36,27.94c10.74,22.45,34.55,33.85,55.96,28.2l69.08,83.07c10.31,18.98,37.31,24.24,60.31,11.75,23-12.49,33.29-38,22.98-56.98-7.01-12.9-21.73-19.34-37.67-18.25-7.2.49-14.28-2.01-19.2-7.29l-1.67-2.09c5.25-4.48,6.49-12.19,2.45-17.96-4.3-6.13-12.75-7.62-18.88-3.33-.13.09-.2.24-.33.34l-10.35-12.94c46.58-11.35,91.9-12.78,122.55-11.88,1.46.04,2.1-1.62,1.09-2.67Z"
    /><path
      class="notification-cls-3"
      d="M254.98,174.28c-15.05-2.65-29.51,3.31-38.7,14.21-1.27,1.52,23.92,67.15,27.08,67.51,21.48,2.33,41.39-12.19,45.2-33.78,3.97-22.51-11.06-43.97-33.57-47.94Z"
    /></g
  ></svg
>
