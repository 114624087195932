<script>
  import Button from "@smui/button";
  import IconButton from "@smui/icon-button";
  import Textfield from "@smui/textfield";
  import { HTTPError } from "ky";
  import { _ } from "svelte-i18n";

  import PasswordStrengthChecker from "~/components/PasswordStrengthChecker.svelte";
  import backendApi from "~/libs/backendApi";
  import { HandledError } from "~/libs/commonTypes";
  import { PasswordStrength } from "~/libs/constants";
  import iosNativeApp from "~/libs/iosNativeApp";
  import loadingProgress from "~/libs/loadingProgress";
  import logger from "~/libs/logger";
  import { toast } from "~/libs/toast";

  /** ユーザーID（読み取り専用） @type {string} */
  export let id;
  /** パスワード @type {string} */
  export let pw;
  /** 初期パスワード変更ページを表示するか否か @type {boolean} */
  export let showsInitialPasswordChangePage;

  /** 新しいパスワード */
  let newPw1 = "";
  /** 新しいパスワードのtype属性値 */
  let newPw1Type = "password";
  /** 新しいパスワード（再入力） */
  let newPw2 = "";
  /** 新しいパスワード（再入力）のtype属性値 */
  let newPw2Type = "password";

  /** エラーメッセージ @type {string} */
  let errorMessage;

  /** パスワード強度 @type {PasswordStrength} */
  let passwordStrength;

  /** 「パスワードを変更」ボタンのdisabled属性を有効にするか否か */
  $: changeInitialPasswordButtonDisabled = !(
    newPw1 &&
    newPw2 &&
    passwordStrength === PasswordStrength.STRONG &&
    newPw1 === newPw2
  );

  const changeInitialPassword = loadingProgress.wrapAsync(async () => {
    try {
      assertNewPassword();
      await backendApi.changeInitialPassword({
        userName: id,
        oldPassword: pw,
        newPassword: newPw1,
      });

      // iOSネイティブアプリの場合は認証情報の保存をサジェストさせる
      iosNativeApp.saveCredentials(id, newPw1);

      toast.info($_("message.pwChangeComplete"));
      errorMessage = null;

      pw = newPw1;
      showsInitialPasswordChangePage = false;
    } catch (error) {
      showErrorMessage(error);
    }
  });

  function assertNewPassword() {
    if (pw === newPw1) {
      throw new HandledError($_("errors.matchOldPassword"));
    }
  }

  /**
   * @param {KeyboardEvent} event
   */
  function onEnterKeyDownHandler(event) {
    if (event.key === "Enter" && !changeInitialPasswordButtonDisabled) {
      changeInitialPassword();
    }
  }

  /**
   * エラーメッセージをダイアログで表示する。
   * @param {Error} error Errorオブジェクト
   */
  function showErrorMessage(error) {
    if (error instanceof HandledError) {
      errorMessage = error.message;
    } else {
      if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 401
      ) {
        errorMessage = $_("errors.loginFailed");
      } else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 400
      ) {
        errorMessage = $_("errors.loginFailedLimitOrver");
      } else {
        logger.error(
          "[InitialPasswordChange] 初期パスワードの変更でエラーが発生しました",
          {
            username: id,
          },
          error,
        );
        errorMessage = $_("errors.loginDefaultMessage");
      }
    }
  }
</script>

<div class="heading">
  <p class="title">初期パスワードの変更</p>
</div>

<p class="pageOverview">
  管理者から割り当てられたパスワードを変更してください。
</p>

<div class="inputField">
  <Textfield
    type="text"
    label="ユーザーID"
    variant="outlined"
    style="margin-top: 30px;"
    input$id="username"
    input$name="username"
    input$autocomplete="username"
    input$readonly
    bind:value={id}
  />
</div>
<div class="inputField passwordField">
  <Textfield
    type={newPw1Type}
    label="新しいパスワード"
    variant="outlined"
    style="margin-top: 15px;"
    required
    input$id="new-password"
    input$name="new-password"
    input$autocomplete="new-password"
    bind:value={newPw1}
    on:keydown={onEnterKeyDownHandler}
  >
    <IconButton
      slot="trailingIcon"
      class="material-icons md-dark"
      tabindex={-1}
      on:click={() => {
        newPw1Type = newPw1Type == "text" ? "password" : "text";
      }}
    >
      {newPw1Type == "text" ? "visibility_off" : "visibility"}
    </IconButton>
  </Textfield>
</div>
<div class="inputField passwordField">
  <Textfield
    type={newPw2Type}
    label="新しいパスワード（再入力）"
    variant="outlined"
    style="margin-top: 15px;"
    required
    input$id="new-password-2"
    input$name="new-password"
    input$autocomplete="new-password"
    bind:value={newPw2}
    on:keydown={onEnterKeyDownHandler}
  >
    <IconButton
      slot="trailingIcon"
      class="material-icons md-dark"
      tabindex={-1}
      on:click={() => {
        newPw2Type = newPw2Type == "text" ? "password" : "text";
      }}
    >
      {newPw2Type == "text" ? "visibility_off" : "visibility"}
    </IconButton>
  </Textfield>
</div>

<div class="passwordStrengthChecker">
  <PasswordStrengthChecker password={newPw1} bind:passwordStrength />
</div>

{#if errorMessage}
  <p class="errorMessage">
    <!-- ja.jsonに定義されたメッセージしか表示されないためHTMLエスケープ不要 -->
    {@html errorMessage}
  </p>
{/if}

<div class="command">
  <Button
    type="submit"
    variant="unelevated"
    style="width: 170px; height: 50px;"
    touch
    bind:disabled={changeInitialPasswordButtonDisabled}
    on:click={changeInitialPassword}
    >パスワードを変更
  </Button>
</div>

<style lang="scss">
</style>
