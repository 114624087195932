<div class="emptyTabContent">
  <span class="material-icons icon">playlist_add_check</span>
  <p class="emptyText">
    <slot name="packageType" />はありません
  </p>
</div>

<style lang="scss">
  .emptyTabContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    .icon {
      font-size: 200px;
      color: #d9d9d9;
    }
    .emptyText {
      margin: -10px 0 50px;
    }
  }
</style>
