<script>
  import { toast } from "@zerodevx/svelte-toast";

  /** @type {string} */
  export let message;

  /**
   * あとで送信するための処理
   * @type {Function}
   */
  export let processForSendLater;
</script>

<p class="note">
  {message}
</p>
<div class="displayHelp">
  <button
    on:click={() => {
      toast.pop();
      processForSendLater();
    }}
  >
    あとで送信する
  </button>
</div>

<style lang="scss">
  .note {
    font-size: 15px;
    line-height: 18px;
  }

  .displayHelp {
    button {
      padding: 0;
      font-size: 14px;
      color: rgb(255, 255, 255);
      text-decoration: underline;
      border: none;
      background-color: transparent;
    }
  }
</style>
