<script>
  import addressUtils from "~/libs/addressUtils";
  import { AddressTypeForMap, GOOGLE_MAP_BASE_URL } from "~/libs/constants";
  import ListItemMapEditDialog from "~/pages/List/ListItemMapEditDialog.svelte";

  /** 配達リストの荷物 @type {import("~/libs/commonTypes").DeliveryPackage} */
  export let listItem;

  /** 表示中の住所区分 */
  const displayingAddressType = {
    [AddressTypeForMap.REGISTERED]: "登録住所",
    [AddressTypeForMap.INPUTTED]: "自分が手入力した住所",
    [AddressTypeForMap.CORRECTED]: "訂正された住所",
  }[listItem.addressForMap];

  /** Googleマップのiframe URL @type {string} */
  let googleMapUrl;
  $: {
    const address = {
      [AddressTypeForMap.REGISTERED]: () =>
        [listItem.receiverAddress1, listItem.receiverAddress2 ?? ""].join(" "),
      [AddressTypeForMap.INPUTTED]: () => listItem.enteredAddress,
      [AddressTypeForMap.CORRECTED]: () => listItem.correctedReceiverAddress,
    }[listItem.addressForMap]();

    addressUtils.normalizeAddressForGoogleMap(address).then((address) => {
      googleMapUrl = GOOGLE_MAP_BASE_URL.replace(
        "<address>",
        encodeURIComponent(address),
      );
    });
  }

  /** 編集ダイアログコンポーネントのインスタンス @type {ListItemMapEditDialog} */
  let editDialog;
</script>

<div class="map">
  <iframe
    title="googlemap"
    src={googleMapUrl}
    width="100%"
    height="350"
    frameborder="0"
    style="border:0;"
  />
  <div class="subArea">
    <p>表示中: {displayingAddressType}</p>
    <button
      class="addressCorrection"
      on:click={() => {
        editDialog.openDialog();
      }}>住所を手入力</button
    >
  </div>
  <ListItemMapEditDialog
    bind:this={editDialog}
    bind:listItem
    on:updateInputAddress
  />
</div>

<style lang="scss">
  .map {
    border-bottom: 1.2px solid #ddd;
  }

  .subArea {
    display: flex;
    justify-content: space-between;
    padding: 1px 0;
    font-size: 14px;

    p {
      margin-top: 4px;
      font-size: 13px;
    }
  }

  .addressCorrection {
    padding: 0;
    font-size: 14px;
    color: #018786;
    background-color: #ffffff00;
    border: none;
    text-decoration: underline;
  }
</style>
