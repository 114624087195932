import iosNativeApp from "~/libs/iosNativeApp";
import { currentPositionStore } from "~/libs/stores";

/** @type {GeolocationPosition} ダミーのGPS座標 */
let dummyPosition;

/** @type {GeolocationPosition} 最後に取得したGPS座標 */
let latestPosition;

const geolocator = {
  /**
   * 現在地を要求する（取得はStore経由）
   * @param {boolean} requiresHighAccuracy 高精度の位置情報が必要か否か
   */
  requestCurrentPosition(requiresHighAccuracy) {
    if (dummyPosition) {
      return;
    }
    if (window.webkit?.messageHandlers?.requestLocation) {
      iosNativeApp.requestLocation(requiresHighAccuracy);
    } else {
      navigator.geolocation?.getCurrentPosition(
        geolocator.successCallback,
        geolocator.errorCallback,
        {
          enableHighAccuracy: requiresHighAccuracy,
          timeout: 30 * 1000, // 30秒
        },
      );
    }
  },

  /**
   * ダミーの現在地を設定する。
   * @param {GeolocationPosition} position
   */
  set dummyPosition(position) {
    dummyPosition = position;
    if (position) {
      currentPositionStore.set(position);
    }
  },

  /**
   * ダミーの現在地を返す。
   * @returns {GeolocationPosition}
   */
  get dummyPosition() {
    return dummyPosition;
  },

  /**
   * 位置情報の取得に成功した際に呼ばれるコールバック関数。
   * @type {PositionCallback}
   */
  successCallback(position) {
    if (
      !latestPosition ||
      latestPosition.coords.latitude !== position.coords.latitude ||
      latestPosition.coords.longitude !== position.coords.longitude
    ) {
      // 緯度経度が変わっている場合のみStoreを更新
      currentPositionStore.set(position);
      console.log("PositionCallback:", position);
    }
    latestPosition = position;
  },

  /**
   * 位置情報の取得に失敗した際に呼ばれるコールバック関数。
   * @type {PositionErrorCallback}
   */
  errorCallback(error) {
    console.warn(`PositionErrorCallback: code=${error.code} ${error.message}`);
  },
};

export default geolocator;
