const arrayUtils = {
  /**
   * 2つの配列が等しいかどうかをShallow Referenceで判定する。
   * @param {Array<*>} lhs
   * @param {Array<*>} rhs
   * @returns {boolean} 2つの配列が等しい場合はtrue
   */
  equals(lhs, rhs) {
    if (lhs === rhs) {
      return true;
    }
    if (
      !Array.isArray(lhs) ||
      !Array.isArray(rhs) ||
      lhs.length !== rhs.length
    ) {
      return false;
    }
    for (let i = 0; i < lhs.length; i++) {
      if (lhs[i] !== rhs[i]) {
        return false;
      }
    }
    return true;
  },
};

export default Object.freeze(arrayUtils);
