/**
 * アプリのビルド時間（UNIX epochミリ秒）
 * @type {number}
 */
// @ts-ignore
// eslint-disable-next-line no-undef
const appBuildTimestamp = __APP_BUILD_TIMESTAMP__;

/**
 * アプリに最低限要求されるバージョンを示すタイムスタンプ（UNIX epochミリ秒）
 * @type {number}
 */
let requiredAppBuildTimestamp;

const appManager = {
  /**
   * 現在のアプリよりも要求バージョンが高い場合はアプリの更新を確認・提案する。
   * @param {number} unixEpoch アプリに最低限要求されるバージョンを示すタイムスタンプ（UNIX epochミリ秒）
   */
  offerUpdateIfNeeded(unixEpoch) {
    requiredAppBuildTimestamp = unixEpoch;
    if (requiredAppBuildTimestamp > appBuildTimestamp) {
      console.log(
        `offerUpdateIfNeeded: ${requiredAppBuildTimestamp} > ${appBuildTimestamp}`,
      );
      appManager.offerUpdate();
    }
  },

  /**
   * アプリの更新を確認・提案する。
   */
  offerUpdate() {
    navigator?.serviceWorker?.getRegistration?.()?.then((registration) => {
      if (
        registration &&
        !registration.installing &&
        navigator &&
        (!("connection" in navigator) || navigator.onLine)
      ) {
        registration.update().catch((error) => {
          console.error(error); // use non-logger explicitly
        });
      }
    });
  },

  /**
   * Service Workerスクリプトを取得する。
   * @param {string} serviceWorkerScriptUrl
   * @returns {Promise<boolean>} Service Workerスクリプトの取得に成功したかどうか
   */
  async fetchServiceWorker(serviceWorkerScriptUrl) {
    const response = await fetch(serviceWorkerScriptUrl, {
      cache: "no-store",
      headers: {
        cache: "no-store",
        "cache-control": "no-cache",
      },
    });
    if (response.status !== 200) {
      return false;
    }
    requiredAppBuildTimestamp = Number.parseInt(
      response.headers.get("x-app-required-time"),
    );
    return true;
  },

  /**
   * アプリのビルド時間（UNIX epochミリ秒）を返す。
   * @returns {number}
   */
  get buildTimestamp() {
    return appBuildTimestamp;
  },

  /**
   * アプリに最低限要求されるバージョンを示すタイムスタンプ（UNIX epochミリ秒）を返す。
   * @returns {number}
   */
  get requiredBuildTimestamp() {
    return requiredAppBuildTimestamp;
  },
};

export default appManager;
