<svg
  id="outForDelivery"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 237.56 230.83"
  class="svg-icons-out-for-delivery"
  ><defs
    ><style>
      .out-for-delivery-cls-1 {
        fill: #14a0de;
      }
      .out-for-delivery-cls-1,
      .out-for-delivery-cls-2 {
        stroke-width: 0px;
      }
      .out-for-delivery-cls-2 {
        fill: #203a72;
      }
    </style></defs
  ><g id="Layer_1"
    ><path
      class="out-for-delivery-cls-1"
      d="M90.8,84.91c11.95-.25,21.78,9.22,22.09,21.1h14.98c4.27,0,7.74-3.47,7.74-7.74v-47.65c0-4.27-3.47-7.74-7.74-7.74h-47.66c-4.27,0-7.74,3.47-7.74,7.74v30.76c.12.12.19.31.31.43,2.78,1.67,11.45,3.22,18.01,3.09Z"
    /><path
      class="out-for-delivery-cls-2"
      d="M106.72,167.72h47.61c4.28,0,7.75,3.47,7.75,7.75v47.61c0,4.28-3.47,7.75-7.75,7.75h-47.61c-4.28,0-7.75-3.47-7.75-7.75v-47.61c0-4.28,3.47-7.75,7.75-7.75Z"
    /><path
      class="out-for-delivery-cls-2"
      d="M182.2,167.72h47.61c4.28,0,7.75,3.47,7.75,7.75v47.61c0,4.28-3.47,7.75-7.75,7.75h-47.61c-4.28,0-7.75-3.47-7.75-7.75v-47.61c0-4.28,3.47-7.75,7.75-7.75Z"
    /><path
      class="out-for-delivery-cls-2"
      d="M182.2,92.32h47.61c4.28,0,7.75,3.47,7.75,7.75v47.61c0,4.28-3.47,7.75-7.75,7.75h-47.61c-4.28,0-7.75-3.47-7.75-7.75v-47.61c0-4.28,3.47-7.75,7.75-7.75Z"
    /><circle
      class="out-for-delivery-cls-2"
      cx="36.69"
      cy="24.77"
      r="24.77"
    /><path
      class="out-for-delivery-cls-2"
      d="M102.28,106.17c-.15-6.14-5.1-10.91-11.4-10.87-8.77.2-22.74-2.05-26.26-6.79-4.15-5.57-7.15-12.45-8.94-17.23-3.51-9.4-12.28-15.76-22.32-16.21-6.78-.29-13.49,2.22-18.39,6.9-4.91,4.69-7.73,11.27-7.75,18.05l-1.11,54.77c-.03.78.01,1.56.13,2.33l5.69,37.86L.48,215.01c-1.88,6.57,1.93,13.42,8.5,15.3,1.14.33,2.28.48,3.41.48,5.39,0,10.34-3.54,11.89-8.98l12.18-42.6c.49-1.7.6-3.49.34-5.24l-4.81-31.99,17.39,34.4,3.51,43.03c.56,6.82,6.58,11.89,13.34,11.33,6.81-.55,11.89-6.53,11.33-13.34l-3.7-45.45c-.13-1.6-.57-3.15-1.29-4.58l-17.74-35.09-.18-23.23c13.29,8.58,32.4,8.63,36.78,8.51,6.14-.16,10.98-5.26,10.84-11.4Z"
    /><path
      class="out-for-delivery-cls-1"
      d="M84.17,124.13c-2.56,0-4.64,2.08-4.64,4.64v14.87c0,2.56,2.08,4.64,4.64,4.64s4.64-2.08,4.64-4.64v-14.87c0-2.56-2.08-4.64-4.64-4.64Z"
    /><path
      class="out-for-delivery-cls-1"
      d="M99.4,128.77v22.3c0,2.56,2.08,4.64,4.64,4.64s4.64-2.08,4.64-4.64v-22.3c0-2.56-2.08-4.64-4.64-4.64s-4.64,2.08-4.64,4.64Z"
    /><path
      class="out-for-delivery-cls-1"
      d="M123.92,124.13c-2.56,0-4.64,2.08-4.64,4.64v14.87c0,2.56,2.08,4.64,4.64,4.64s4.64-2.08,4.64-4.64v-14.87c0-2.56-2.08-4.64-4.64-4.64Z"
    /></g
  ></svg
>
