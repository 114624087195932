import { REMIND_HOURS, REMIND_MINUTES } from "~/libs/constants";

/** @type {Worker} */
let worker;

/** @type {string} */
let timeForTest;

const reminder = {
  remindOn(accessToken) {
    let remindTime = new Date().setHours(REMIND_HOURS, REMIND_MINUTES, 0, 0);

    // 検証用時間が設定されている場合
    if (timeForTest) {
      const timeForTestHours = Number(timeForTest.split(":")[0]);
      const timeForTestMinutes = Number(timeForTest.split(":")[1]);
      remindTime = new Date().setHours(
        timeForTestHours,
        timeForTestMinutes,
        0,
        0,
      );
    }

    // 通知時刻より前ならリマインドON
    if (new Date().getTime() < remindTime) {
      worker = new Worker(new URL("../libs/remindWorker.js", import.meta.url), {
        type: "module",
      });
      const waitingTime = remindTime - new Date().getTime();
      console.log("remind ON! --> " + waitingTime / 1000 + "秒後に通知");
      worker.postMessage({
        accessToken: accessToken,
        waitingTime: waitingTime,
      });
    }
  },

  remindOff() {
    if (worker) {
      console.log("remind OFF!");
      worker.terminate();
    }
  },

  /**
   * 検証用の通知時刻を設定する。
   * @param {string} time
   */
  set timeForTest(time) {
    timeForTest = time;
  },

  /**
   * 検証用の通知時刻を返す。
   * @returns {string} timeForTest
   */
  get timeForTest() {
    return timeForTest;
  },
};
export default reminder;
