import backendApi from "~/libs/backendApi";
import { BackendApiName } from "~/libs/constants";
import { db } from "~/libs/db";

const requestsQueueForRetry = {
  /**
   * 配送ステータスの更新
   * @param {string} trackingNumber
   * @param {[string, FormDataEntryValue][]} data
   * @returns {Promise<string>} 登録時に自動採番されたID
   */
  async updateShipmentStatus(trackingNumber, data) {
    return await addRequestsQueue(
      BackendApiName.UPDATE_SHIPMENT_STATUS,
      trackingNumber,
      data,
    );
  },

  /**
   * idを指定して送信待ちリクエストを再送する
   * @param {string} id
   * @returns {Promise<{
   *   trackingNumber: string, // 更新対象の送り状番号
   *   updateResponse: import("~/libs/backendApi").UpdateShipmentStatusResponse, // 更新結果
   *   updateRequestJson: object, // 更新リクエストのJSON
   * }>}
   */
  async resendById(id) {
    // DBから該当のリクエストを取得
    const request = await this.getRequestsById(id);

    // リクエストからJSON部分(status-update-event)を抽出
    let updateRequestJson;
    if (request.data[0][0] == "status-update-event") {
      updateRequestJson = JSON.parse(
        await request.data[0][1].text().then((json) => json),
      ).events[0];
    } else {
      updateRequestJson = JSON.parse(
        await request.data[1][1].text().then((json) => json),
      ).events[0];
    }

    // API実行（現状はステータス更新APIのみ対応）
    const updateResponse = await execUpdateShipmentStatusApi(request);

    return {
      trackingNumber: request.trackingNumber,
      updateResponse,
      updateRequestJson,
    };
  },

  /**
   * idを指定してリクエスト情報を取得する。
   * @param {string} id
   * @returns {Promise<import("~/libs/db").RequestsQueueForRetry>}
   */
  async getRequestsById(id) {
    return await db.requestsQueueForRetry.where("id").equals(id).first();
  },

  /**
   * idを指定してリクエスト情報を削除する。
   * @param {string} id
   */
  async deleteRequestsById(id) {
    await db.requestsQueueForRetry.delete(id);
  },
};
export default requestsQueueForRetry;

/**
 * indexedDBにリクエスト情報を登録する。
 * @param {string} backendApiName
 * @param {string} trackingNumber
 * @param {object} data
 * @returns {Promise<string>} 登録時に自動採番されたID
 */
async function addRequestsQueue(backendApiName, trackingNumber, data) {
  const record = {
    trackingNumber: trackingNumber,
    backendApiName: backendApiName,
    data: data,
  };

  return await db.requestsQueueForRetry.add(record);
}

/**
 * ステータス更新APIを実行
 * @param {import("~/libs/db").RequestsQueueForRetry} request
 * @returns {Promise<object>}
 */
async function execUpdateShipmentStatusApi(request) {
  const newBody = new FormData();
  for (let [key, value] of request.data) {
    newBody.append(key, value);
  }
  const response = await backendApi.updateShipmentStatus(newBody);
  return response;
}
