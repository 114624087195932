<svg
  id="returnPackage"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 271.28 233.24"
  class="svg-icons-return-package"
  height="100"
  width="100"
  ><defs
    ><style>
      .return-cls-1 {
        fill: #14a0de;
      }
      .return-cls-1,
      .return-cls-2 {
        stroke-width: 0px;
      }
      .return-cls-2 {
        fill: #203a72;
      }
    </style></defs
  ><g id="Layer_1"
    ><path
      class="return-cls-1"
      d="M196.73,33.09H66.38V9.94c0-3.76-2.12-7.2-5.49-8.89-3.35-1.67-7.38-1.33-10.41.9L4.01,36.53c-2.53,1.89-4.01,4.84-4.01,7.99s1.49,6.1,4.01,7.99l46.47,34.58c1.75,1.3,3.84,1.96,5.94,1.96,1.53,0,3.06-.35,4.47-1.06,3.36-1.69,5.49-5.13,5.49-8.89v-23.12h130.35c28.47,0,51.66,23.19,51.66,51.56,0,25.78-19.01,47.28-43.8,50.97v23.19c37.43-3.88,66.69-35.73,66.69-74.16,0-41.01-33.44-74.45-74.55-74.45Z"
    /><path
      class="return-cls-2"
      d="M57.83,131.27c-.95-.43-2.03.26-2.03,1.3v74.31c0,.55.33,1.06.84,1.29l54.53,24.93c.95.43,2.03-.26,2.03-1.3v-74.3c0-.56-.33-1.07-.84-1.3l-54.53-24.93Z"
    /><path
      class="return-cls-2"
      d="M174.26,115.16l-51.98-23.77c-.38-.18-.81-.18-1.19,0l-51.98,23.77c-1.12.52-1.12,2.1,0,2.61l51.98,23.76c.38.18.81.18,1.19,0l51.98-23.76c1.12-.51,1.12-2.09,0-2.61Z"
    /><path
      class="return-cls-2"
      d="M185.54,131.27l-54.54,24.93c-.51.23-.84.74-.84,1.3v74.3c0,1.04,1.08,1.73,2.03,1.3l54.54-24.93c.51-.23.84-.74.84-1.3v-74.3c0-1.04-1.08-1.73-2.03-1.3Z"
    /></g
  ></svg
>
