import { format as formatDate } from "date-fns";

import { BackendApiName } from "~/libs/constants";
import { db } from "~/libs/db";

const offlineBackendApi = {
  /**
   * ログイン
   * @param {{username: string, password: string}} data
   */
  async login(data) {
    addRequestsQueue(BackendApiName.LOGIN, data);
  },

  /**
   * 配送ステータスの更新
   * @param {[string, FormDataEntryValue][]} data
   */
  async updateShipmentStatus(data) {
    addRequestsQueue(BackendApiName.UPDATE_SHIPMENT_STATUS, data);
  },
};
export default offlineBackendApi;

/**
 * indexedDBにリクエスト情報を登録する。
 * @param {string} backendApiName
 * @param {object} data
 */
function addRequestsQueue(backendApiName, data) {
  const id = formatDate(new Date(), "yyyyMMddHHmmss");
  const record = {
    id: id,
    backendApiName: backendApiName,
    data: data,
  };
  console.log(record);

  db.requestsQueue.add(record);
}
