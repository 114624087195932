<script>
  import { datadogLogs } from "@datadog/browser-logs";
  import { SvelteToast, toast } from "@zerodevx/svelte-toast";
  import { getContext, onDestroy, setContext } from "svelte";
  import {
    addMessages,
    getLocaleFromNavigator,
    init as i18nInit,
  } from "svelte-i18n";

  import AppReloadPrompt from "~/components/AppReloadPrompt.svelte";
  import { destroyAudioContext } from "~/libs/audio";
  import backendApi from "~/libs/backendApi";
  import { AppContext, UserContext } from "~/libs/commonTypes";
  import {
    AppPageTypes,
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    NotificationCategory,
    TOAST_DURATION,
  } from "~/libs/constants";
  import iosNativeApp from "~/libs/iosNativeApp";
  import localStorages from "~/libs/localStorages";
  import logger from "~/libs/logger";
  import notificationHistoryUtils from "~/libs/notificationHistoryUtils";
  import { appPageStore } from "~/libs/stores";
  import svelteContextMigrator from "~/libs/svelteContextMigrator";
  import i18n_ja from "~/locales/ja.json";
  import Login from "~/pages/Login.svelte";
  import Main from "~/pages/Main.svelte";
  import Offline from "~/pages/Offline.svelte";

  // iOSネイティブアプリ向けの初期化
  iosNativeApp.initializeWKWebView();

  // Datadogを使用しない環境用の書き換え処理
  _REMOVABLE_UNUSED_DATADOG_: {
    // Initialize Datadog logger (devで起動している場合は除外)
    if (!import.meta.env.DEV) {
      datadogLogs.init({
        clientToken: "pub3742f1b67b032ddc0bbe777ec305b9a4",
        site: "ap1.datadoghq.com",
        service: "raptortms-delivery",
        env: import.meta.env.MODE,
        version: (import.meta.env.VITE_COMMIT_HASH || "n/a").substring(0, 7),
        forwardErrorsToLogs: false,
        sessionSampleRate: 100,
        telemetrySampleRate: 100,
      });
    }
    break _REMOVABLE_UNUSED_DATADOG_; // 未使用ラベルがViteの事前処理で削除されてesbuildに渡せない対策
  }

  // 全ページで共有するアプリケーションのコンテキスト情報を初期化（Context APIでアクセス）
  try {
    // アプリケーション固有のコンテキスト情報
    const appContext = new AppContext();
    if (localStorages.appContext) {
      const storedAppContext = JSON.parse(localStorages.appContext);
      Object.assign(appContext, storedAppContext);
    } else {
      appContext.version = 0; // 初期バージョンに0（データなし）を設定
    }
    svelteContextMigrator.migrateAppContext(appContext);
    setContext(CONTEXT_KEY_APP, appContext);

    // ユーザ固有のコンテキスト情報
    const userContext = new UserContext();
    if (localStorages.userContext) {
      const storedUserContext = JSON.parse(localStorages.userContext);
      Object.assign(userContext, storedUserContext);
    } else {
      userContext.version = 0; // 初期バージョンに0（データなし）を設定
    }
    svelteContextMigrator.migrateUserContext(userContext);
    setContext(CONTEXT_KEY_USER, userContext);

    window.addEventListener("beforeunload", () => {
      // Windowが閉じられる前に、コンテキスト情報をlocalStorageに保存
      appContext.store();
      userContext.store();

      // AudioContextを破棄（iOSはAudioContextを破棄しないと次回起動時に音が鳴らなくなる）
      destroyAudioContext();
    });

    console.log("[App.svelte]", appContext, userContext);
  } catch (error) {
    logger.error(
      "[App] アプリケーションコンテキストの初期化でエラーが発生しました",
      {},
      error,
    );
  }

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  // kyの初期化
  backendApi.initialize(userContext);

  // toastの重複メッセージを削除するSubscriberを設定
  /** @type {import("svelte/store").Unsubscriber} */
  const toastUnsubscriber = toast.subscribe((v) => {
    if (!Array.isArray(v)) {
      return;
    }
    const msgs = new Set();
    for (let i = 0; i < v.length; i++) {
      if (msgs.has(v[i].msg)) {
        toast.pop(v[i].id);
      }
      msgs.add(v[i].msg);
    }
  });

  // svelte-i18nの初期化
  addMessages("ja", i18n_ja);
  i18nInit({
    fallbackLocale: "ja",
    initialLocale: getLocaleFromNavigator(),
  });

  /** 現在のAppPage @type {import("~/libs/commonTypes").AppPageStore} */
  let currentAppPage = (() => {
    // オフラインモードの場合は、オフラインモード画面に遷移
    if (appContext.offlineMode && !appContext.failedToSwitchOnline) {
      return {
        type: AppPageTypes.OFFLINE_MODE,
        name: Offline.name,
        props: { offlineModeType: appContext.offlineModeType },
      };
    }
    // ログイン要否に応じて初期表示する画面を切り替える
    if (userContext.needsLogin()) {
      return { type: AppPageTypes.BEFORE_LOGIN, name: Login.name };
    } else {
      console.log("認証トークンが有効なためログイン後画面に遷移");
      return { type: AppPageTypes.AFTER_LOGIN, name: Main.name };
    }
  })();
  appPageStore.set(currentAppPage);

  /** AppPageStoreのUnsubscriber @type {import("svelte/store").Unsubscriber} */
  const appPageStoreUnsubscriber = appPageStore.subscribe((appPage) => {
    if (appPage && currentAppPage !== appPage) {
      currentAppPage = appPage;
    }
  });

  onDestroy(() => {
    appPageStoreUnsubscriber?.();
    toastUnsubscriber?.();
  });

  function showUnhandledErrorAlert() {
    setTimeout(() => {
      window.alert(
        "アプリで内部エラーが発生しました。OKボタンを押すとページを再読込します。",
      );
      window.location.reload();
    }, 1000);
  }
</script>

<svelte:window
  on:unhandledrejection|preventDefault={(event) => {
    try {
      const message = `window.onunhandledrejection: ${event.reason}`;
      logger.error(
        message,
        {
          currentAppPage: currentAppPage,
          username: userContext.loginUser?.username,
        },
        event.reason instanceof Error ? event.reason : undefined,
      );
      notificationHistoryUtils.deleteAndAddHistory(
        userContext.loginUser?.username,
        NotificationCategory.ERROR,
        "[アプリ内部エラー] " + message,
      );
      showUnhandledErrorAlert();
    } catch (error) {
      console.error(error);
    }
  }}
  on:error|preventDefault={(event, source, lineno, colno, error) => {
    try {
      let message = "window.onerror: ";
      if (event instanceof ErrorEvent) {
        if (
          event.message ===
            "Uncaught Error: Tried to dipatch event without element." ||
          event.message === "Error: Tried to dipatch event without element."
        ) {
          // SMUIのMenuをフォーカスアウトで閉じたときに発生するエラーは無視
          return;
        }
        message += event.message;
        error = event.error;
      } else {
        message += `${event} (${source}:${lineno}:${colno})`;
      }

      logger.error(
        message,
        {
          currentAppPage: currentAppPage,
          username: userContext.loginUser?.username,
        },
        error instanceof Error ? error : undefined,
      );
      notificationHistoryUtils.deleteAndAddHistory(
        userContext.loginUser?.username,
        NotificationCategory.ERROR,
        "[アプリ内部エラー] " + message,
      );
    } catch (error) {
      console.error(error);
    }
  }}
/>

<!-- 誤操作によるズームを防止するためダブルタップを無効化 -->
<svelte:body
  on:dblclick|capture|nonpassive={(event) => {
    event.preventDefault();
  }}
/>

{#if currentAppPage?.type === AppPageTypes.AFTER_LOGIN}
  <Main />
{:else if currentAppPage?.type === AppPageTypes.BEFORE_LOGIN}
  <Login />
{:else if currentAppPage?.type === AppPageTypes.OFFLINE_MODE}
  <Offline offlineModeType={currentAppPage.props.offlineModeType} />
{/if}

<SvelteToast
  options={{
    duration: TOAST_DURATION,
    pausable: false,
  }}
/>

{#if import.meta.env.PROD}
  <AppReloadPrompt />
{/if}
