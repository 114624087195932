<script>
  import Button, { Label } from "@smui/button";
  import Dialog, { Content, Header, Title } from "@smui/dialog";
  import IconButton from "@smui/icon-button";
  import List, { Graphic, Item, Meta, Text } from "@smui/list";
  import { HTTPError } from "ky";
  import sleep from "sleep-promise";
  import { getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import backendApi, { OfflineException } from "~/libs/backendApi";
  import {
    CONTEXT_KEY_USER,
    CORE_DELIVERY_ROLE,
    DRIVER_ROLE,
  } from "~/libs/constants";
  import loadingProgress from "~/libs/loadingProgress";
  import logger from "~/libs/logger";
  import { toast } from "~/libs/toast";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {boolean} 設定ダイアログの開閉フラグ */
  let dialogOpend = false;

  /**
   * 切り替え可能なロールのリスト
   * 現時点では固定で宅配業務と基幹輸送業務のみ
   * @type {Array<string>}
   */
  let switchableRoles = [DRIVER_ROLE, CORE_DELIVERY_ROLE];

  /** 選択中ロールのindex @type {number} */
  let selectedRoleIndex;

  /**
   * ダイアログを開く。
   */
  export function openDialog() {
    selectedRoleIndex = userContext.loginUser.roles[0] === DRIVER_ROLE ? 0 : 1;
    dialogOpend = true;
  }

  const changeRole = loadingProgress.wrapAsync(async () => {
    try {
      // ログインAPI呼び出し
      const loginResponse = await backendApi.loginToSwitchCompany(
        {
          username: userContext.loginUser.username,
          password: "N/A",
        },
        switchableRoles[selectedRoleIndex],
      );

      // ログイン情報をUserContextに保存
      const currentTime = Date.now();
      userContext.loginUser.roles = loginResponse.roles;
      userContext.loginUser.accessToken = loginResponse.accessToken;
      userContext.loginUser.refreshToken = loginResponse.refreshToken;
      userContext.loginUser.expiresIn = loginResponse.expiresIn;
      userContext.loginUser.refreshExpires =
        loginResponse.refreshExpiresIn > 0
          ? currentTime + loginResponse.refreshExpiresIn * 1000
          : undefined;
      userContext.loginUser.loginTime = currentTime;

      // UserContextの設定を保存してホーム画面に移動
      userContext.store();
      window.location.reload();
      // 画面上更新が走るまで2秒程度かかるため待機
      await sleep(2000);
    } catch (error) {
      if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 401
      ) {
        toast.error($_("errors.unauthorized"));
      } else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 403
      ) {
        toast.error($_("errors.forbidden"));
      } else if (error instanceof OfflineException) {
        toast.error($_("errors.offline"));
      } else {
        // サーバーエラー応答等が発生した場合
        logger.error(
          "[RoleChangeDialog] ロール切替えでエラーが発生しました",
          {
            username: userContext.loginUser?.username,
          },
          error,
        );
        toast.error($_("errors.defaultMessage"));
      }
    }
  });
</script>

{#if dialogOpend}
  <div class="configurationDialog">
    <Dialog bind:open={dialogOpend}>
      <Header>
        <Title>業務切替</Title>
        <IconButton action="close" class="material-icons" ripple={false}
          >close</IconButton
        >
      </Header>

      <Content>
        <List class="demo-list" avatarList singleSelection>
          {#each switchableRoles as role, i}
            <Item
              on:SMUI:action={() => (selectedRoleIndex = i)}
              selected={selectedRoleIndex === i}
            >
              <Graphic
                style="color: #fff; background-color: #018786; border-radius: 50%;"
                >{$_(`classes.shortRoleName.${role}`)}</Graphic
              >
              <Text>
                {$_(`classes.businessName.${role}`)}
              </Text>
              {#if selectedRoleIndex === i}
                <Meta class="material-icons" style="color: #018786;">done</Meta>
              {/if}
            </Item>
          {/each}
        </List>
        <div class="buttonArea">
          <Button
            touch
            disabled={switchableRoles[selectedRoleIndex] ===
              userContext.loginUser.roles[0]}
            color="secondary"
            on:click={changeRole}
          >
            <Label>切替</Label>
          </Button>
        </div>
      </Content>
    </Dialog>
  </div>
{/if}

<style lang="scss">
  .configurationDialog {
    :global(.mdc-dialog) {
      z-index: 201;
    }

    :global(.mdc-dialog__header .mdc-icon-button) {
      position: absolute;
      top: 5px;
      right: 5px;
    }

    :global(.mdc-deprecated-list-item--selected) {
      color: #018786;
    }

    :global(
        :not(
            .mdc-deprecated-list-item--disabled
          ).mdc-deprecated-list-item--selected
          .mdc-deprecated-list-item__ripple::before
      ) {
      background-color: #018786;
    }

    .buttonArea {
      text-align: right;
    }
  }
</style>
