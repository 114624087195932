<script>
  import Button, { Label } from "@smui/button";
  import CircularProgress from "@smui/circular-progress";
  import { useRegisterSW } from "virtual:pwa-register/svelte";

  import appManager from "~/libs/appManager";
  import { AppPageTypes } from "~/libs/constants";
  import { appPageStore } from "~/libs/stores";
  import { toast } from "~/libs/toast";

  /** Service WorkerスクリプトのURL @type {string} */
  let serviceWorkerScriptUrl;

  /** アプリケーションの更新中フラグ */
  let updateInProgress = false;

  /** ページが非表示になった時点のタイムスタンプ（UNIX epochミリ秒） @type {number} */
  let pageHiddenTime;

  const { needRefresh, updateServiceWorker } = useRegisterSW({
    immediate: true,

    onRegisteredSW(swScriptUrl, registration) {
      console.log("Service Worker registered:", swScriptUrl, registration);
      serviceWorkerScriptUrl = swScriptUrl;
    },

    onRegisterError(error) {
      console.error(error); // use non-logger explicitly
    },

    onOfflineReady() {
      console.log("Service Worker is offline-ready");
    },

    onNeedRefresh() {
      forceUpdateIfNeeded();
    },
  });

  /**
   * 必要な場合はアプリを強制的に更新する。
   */
  async function forceUpdateIfNeeded() {
    if (updateInProgress) {
      return;
    }

    if (!Number.isInteger(appManager.requiredBuildTimestamp)) {
      if (!serviceWorkerScriptUrl) {
        return;
      }
      try {
        await appManager.fetchServiceWorker(serviceWorkerScriptUrl);
      } catch (error) {
        console.error(error); // use non-logger explicitly
        return;
      }
    }

    if (appManager.requiredBuildTimestamp > appManager.buildTimestamp) {
      updateInProgress = true;
      toast.info("アプリのバージョンが古いため自動更新を行います。");
      console.log(
        `Force update application: ${appManager.requiredBuildTimestamp} > ${appManager.buildTimestamp}`,
      );
      setTimeout(onclickUpdateButton, 3000);
    }
  }

  async function onclickUpdateButton() {
    updateInProgress = true;
    await updateServiceWorker(true);
    setTimeout(() => {
      if ($needRefresh) {
        console.log("Service Worker reload timeout");
        window.location.reload();
      }
    }, 10000);
  }

  function onclickCloseButton() {
    needRefresh.set(false);
  }
</script>

<svelte:document
  on:visibilitychange={() => {
    const currentTime = Date.now();
    if (document.visibilityState === "visible") {
      if (pageHiddenTime != undefined) {
        const currentAppPage = $appPageStore;
        if (
          currentAppPage?.type === AppPageTypes.BEFORE_LOGIN ||
          currentTime - pageHiddenTime >= 15 * 60 * 1000
        ) {
          // 未ログインページにいるか15分以上非表示だった場合は更新をチェック
          console.log(
            `visibilitychange: page=${currentAppPage?.type}` +
              `, pageAppearedAfter=${(currentTime - pageHiddenTime) / 1000}secs`,
          );
          appManager.offerUpdate();
        }
        pageHiddenTime = undefined;
      }
    } else {
      pageHiddenTime = currentTime;
    }
  }}
/>

{#if $needRefresh}
  <div class="pwa-toast" role="alert">
    <div class="message">
      <span>置ToCアプリの新しいバージョンがあります。今すぐ更新しますか？</span>
    </div>
    {#if updateInProgress}
      <div style="display: flex; justify-content: center; margin-bottom: 7px;">
        <CircularProgress style="height: 28px; width: 28px;" indeterminate />
      </div>
    {/if}
    <Button
      on:click={onclickUpdateButton}
      color="secondary"
      variant="outlined"
      disabled={updateInProgress}
    >
      <Label>更新する</Label>
    </Button>
    <Button
      on:click={onclickCloseButton}
      color="secondary"
      variant="outlined"
      disabled={updateInProgress}
    >
      <Label>閉じる</Label>
    </Button>
  </div>
{/if}

<style>
  .pwa-toast {
    position: fixed;
    right: 0;
    bottom: 0;
    margin: 16px;
    padding: 12px 14px;
    border: 1px solid #8885;
    border-radius: 4px;
    z-index: 1;
    text-align: left;
    box-shadow: 3px 4px 5px 0 #8885;
    background-color: white;
  }
  .pwa-toast .message {
    margin-bottom: 9px;
  }
</style>
